.lay-search_terms_transitionGraph {
  margin-bottom: 48px;
}

.lay-search_terms_transitionGraph .lay-search_terms_transitionGraph-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lay-search_terms_transitionGraph .lay-search_terms_transitionGraph__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 698px; 
  padding: 15px 0;
  background-color: #f0f0f0;
}

.lay-search_terms_transitionGraph .lay-search_terms_transitionGraph__list__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
  margin-left: 20px;
  width: 536px;
}

.lay-search_terms_transitionGraph .lay-search_terms_transitionGraph__list__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 143px;
}

.lay-search_terms_transitionGraph .lay-search_terms_transitionGraph__list__title img {
  width: 100%;
}

.lay-transitiongraph {
  margin-bottom: 49px;
}

.lay-transitiongraph__fig {
  margin-bottom: 41px;
}

.trans_slideshow-container {
  width: 830px;
  position: relative;
  margin: auto;
}

.trans_measure_unit {
  position: relative;
  top: 15px;
  right:-4px;
  z-index: 1;
  font-size: 13px;
}

.transtabs {
  display: inline-block; 
  background-color: #fff;
  margin: 0 auto;
  margin-left:650px; 
  width:250px;
}

.transtabs input[name="trans_graph_type"] {
  display: none;
  margin-top:10px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.transtabs input[name="trans_graph_type"]:checked + .trans_graph_item {
  color: #f00;
}

.trans_graph_item {
  width: calc(100%/2);
  height: 38px;
  /*border: 1px solid #ccc ;*/
  line-height: 38px;
  text-align: center;
  display: block;
  float: left;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
}
.trans_graph_item:hover {
  opacity: 0.75;
  background-color: #99ffbb;
}

.trans_graph_prev, .trans_graph_next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  margin: -50px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: pink;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;
  user-select: none;
}

.trans_graph_next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

.trans_graph_prev:hover, .trans_graph_next:hover {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
}

.trans_chart_area {
  /*width: 1250px;*/ /*20200313*/
  width: 830px;
  height: 550px;
  position: relative;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  overflow-x: auto; 
}

#tchart {
  position: absolute; 
  left: 0; 
  top: 0; 
}

.trans_modal_view {
  position: absolute;
  width: 600px;
  height: 220px;
  margin: auto;
  border: solid 3px #F00;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
  pointer-events: none;
  transform: translateY(-10px);
  background-repeat: no-repeat;
  background-size: 30px 27px;
  background-position: top 30px center;
  overflow: hidden; 
}

.trans_text_view {
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
  margin-top: -15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.trans_diff_view {
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.trans_measure_view {
	border: solid 1px;
	padding: 0px 10px;
}

.transdots {
  text-align:center;
}

/* The dots/bullets/indicators */
.transdot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  line-height: 1.5;
}

.active, .transdot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.lay-trans_graph__btn {
  width: 563px;
  margin: 0 auto;
}

.lay-trans_graph__btn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.lay-trans_graph__btn ul li {
  width: 270px;
}

.lay-trans_graph__btn .mod-btn__round {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  background-color: #008eab;
  border-radius: 23px;
}

.lay-trans_graph__btn .mod-btn__round:hover {
  background-color: #f6ab00;
  border: #f6ab00 2px solid;
}

.lay-trans_graph__btn .mod-btn__round:focus,
.lay-trans_graph__btn .mod-btn__round:active {
  outline: none;
}

.lay-trans_graph__btn .mod-btn__roundborder {
  text-align: center;
  display: block;
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
}

.lay-trans_graph__btn .mod-btn__roundborder:hover {
  color: #f6ab00;
  border: #f6ab00 2px solid;
}

.overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

@media (max-width: 1200px) {
  .lay-search_terms_transitionGraph .lay-search_terms_transitionGraph__list {
    width: 90%;
  }

  .transtabs {
    margin-left:450px; 

  }

  .trans_slideshow-container {
    width: 630px;
  }

  .trans_chart_area {
    width: 630px;
    height: 500px;

  }

  .trans_graph_prev, .trans_graph_next {
    margin: -45px;
  }
}

@media (max-width: 900px) {

  .transtabs {
    margin-left:450px; 
  }

  .trans_slideshow-container {
    width: 630px;
  }

  .trans_chart_area {
    width: 630px;
    height: 420px;

  }

} 