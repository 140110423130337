.lay-login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-color: rgba(192, 192, 192, 0.2);
}

.lay-login__sub {
    border: 1px solid #ccc;
    width: 350px;
    height: 500px;
    background-color: #fff;
    border-radius: 20px;
}

.lay-login__check .lay-login__sub {
    border: 1px solid #ccc;
    width: 300px;
    height: 450px;
    background-color: #fff;
    border-radius: 20px;
}

.lay-login__sub img {
    margin: 20px 30%;
    width: 150px;
}

.login-form {
    margin: 20px 20px;
}

.login-form p {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    background: #e83e8c;
    width: 100% !important;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.login-form>form>label {
    margin-bottom: 0;
    /* padding-top: 10px; */
}

.login-form input[type="text"], input[type="password"] {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.login-form input[type="text"]:focus,
input[type="password"]:focus {
	border: 1px solid rgb(120, 120, 120);
}

input[type='checkbox'], input[type='radio'], input[type='submit'], input[type='button'], label, button, select {
    cursor: pointer;
}

.login-form .checkbox-input:checked {
    color: #09ad7e;
}

.login-form .checkbox-frame {
    padding-top: 10px;
    padding-left: 25px;
}

.login-form .checkbox-input:checked {
    color: #09ad7e;
}

.checkbox-input:checked {
    color: #09ad7e;
}

.login-form .checkbox-parts {
    padding-left: 20px;
    position: relative;
    margin-right: 20px;
    font-size: 13px;
}

.login-form .checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    border-radius: 4px;
}

.login-form .checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 5px;
    height: 10px;
    transform: rotate(20deg);
    border-bottom: 2px solid #09ad7e;
    border-right: 2px solid #09ad7e;
}

.login-form button {
    margin-top: 30px;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    width: 100%;
    height: 40px;
    background-color: #1E90FF;
    border-radius: 10px;
}

.otp-check-div {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

#resend-otp {
    text-align: center;
    margin-top: 20px;
    margin-left: 8px;
    font-size: 16px;
}

#resend-otp em{
    color: red;
    text-decoration: underline;
    font-style: normal;
}

#resend-otp em:hover{
    cursor: pointer;
}

.otp-check-div input[type="text"] {
    width: 120px;
    height: 40px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 1.7;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.otp-check-div button {
    margin-top: 0px;
    width: 120px;
    position: relative;
}

.login-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-left: 10px;
    vertical-align: middle; /* Căn giữa theo chiều dọc */
    position: absolute;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
}
}

button:disabled {
    cursor: auto;
}

button .spinner-container {
    position: relative;
    display: inline-block;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
  
.modal-content {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #c5dbec;
    width: 400px;
    height: auto;
    padding: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-text {
    width: 100%;
    margin-top: 30px;
}
  
.modal-close {
    position: absolute;
    top: 2px;
    right: 8px;
    cursor: pointer;
    font-size: 12px;
    background-color: #d6e3ed;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #2e6e9e;
    border-radius: 4px;
    border: 1px solid #c5dbec;
    margin-top: 5px;
}

.modal-divider {
    width: 100%;
    margin: 5px 0;
    border: 0;
    border-top: 1px solid #a6c9e2;
}

.btn-modal-close {
    width: 80px;
    height: 30px;
    background: #888;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #c5dbec;
}
