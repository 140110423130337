/* PhotoShootDenyPopup */
#confirm_popup {
    max-width: 450px;
}

#confirm_popup .popup-content {
    background: #dcf1fa !important;
    height: 220px !important;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.icon-img {
    width: 60px;
    height: 60px; 
    margin: 10px 0px;
    background-image: url(/public/img/common/icon-confirm.png) ;
    background-size: cover;
}

.confirm-content-icon {
    display: flex;
    justify-content: center;
    border: none;
}

.confirm-content-message {
    display: flex;
    justify-content: center;
    text-align: center;
}

.confirm-content-button {
    display: flex;
    justify-content: flex-end; /* Standard Flexbox value */
    margin-bottom: 5px;
}
.confirm-content-button button {
    width: 90px;
    margin: 0px 10px;
}

#confirm_popup {
    max-width: 450px;
}