  .overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

@media (max-width: 767px) {
  .display-main {
    padding-top: 100px;
  }

  .list {
    width: 100%;
  }

}

@media (max-width: 990px) and (max-height: 560px) and (orientation: landscape) {
  .display-main {
    padding-left: 80px;
    padding-top: 0;
  }

  .list {
    width: 100%;
  }
}

@media (min-width: 436px) and (max-width: 991px) {
  .list {
    width: 100%;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .searchCondition {
    width: 100%; 
  }
  
  .list {
    width: 100%;
  }

}


