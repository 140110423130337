.lay-master-set {
    margin-bottom: 65px;
}

.lay-master-set-wrap {
    padding: 60px;
    background-color: #dcf1fa;
    border-radius: 35px;
}

.lay-master-set__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-master-set__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/navi/icon-setting.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}

.lay-master-set__form {
    margin-bottom: 48px;
    padding-bottom: 8px;
    border-bottom: #fff 1px solid;
}

.lay-master-set__form .wrapper {
    margin-left: 15%;
    width: 70%;
    height: 80%;
}

.lay-master-set__form .set_master_name {
    margin-top: 20px;
    margin-bottom: 50px;
}

.lay-master-set__form .set_master_name input[type="text"] {
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
}

.lay-master-set .trun-btn {
    text-align: center;
}

.master_long_btn {
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 300px;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
}

.loader {
    position: fixed;
    top: 50%;
    left: 60%;
    z-index: 999;
}

.loader.active {
    display: block;
    width: 24px;
    height: 24px;
    background: url(/public/img/common/loader.gif);
}