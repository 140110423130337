.lay-breadcrumb {
  margin-bottom: 19px;
  width:100%;
  display: flex;
}

.lay-breadcrumb ul li {
  display: inline-block;
  font-size: 14px;
}

.lay-breadcrumb ul li::after {
  content: "/";
  margin: 0 8px 0 11px;
}

.lay-breadcrumb ul li:last-child::after {
  content: "";
}

.bread_btn {
  font-size: 14px;
  text-decoration: underline;
  color: #0091a7;
}

.bread_btn:hover {
  text-decoration: none;
}

.lay-breadcrumb  .item {
  width:70%;
}

.lay-breadcrumb .option {
  width:410px;
  text-align: right;
  padding-right: 3px;
  font-size: 14px;
  color:#444;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: clip;
}