* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  overflow: hidden;
  overflow-x: auto;
  /* display: flex; */
}

.loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: gray;
}

.not-found {
  width: 100vw;
  height: 100vh;
  display: table-cell;
  vertical-align: middle;
  color: red;
  text-align: center;
  user-select: none;
}

.content-container {
  display: block;
  width: calc(100% - var(--menu-width));
  margin: 0 0 1px 10px;
}

.table-root {
  width: 100%;
}

.table-container {
  max-height: calc(100vh - 90px) !important;
}

/* .MuiTableCell-root {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-left: 1px solid rgba(224, 224, 224, 1);
} */

.pagination-button {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.formcontrol-filter{
  width: 250px;
  margin: 0px 30px 0px 0 !important;
}

.loginname {
  font-size: 16px;
  text-align: right;
  margin: -22px 30px 1px 0px;
}

.formcontrol-filter .MuiOutlinedInput-input {
  text-align: right !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.MuiOutlinedInput-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.MuiMenuItem-root {
  min-height: 32px !important;
}

.MuiAutocomplete-option {
  min-height: 32px !important;
}

input, button, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

ul {
  list-style: none;
}

button:focus, button:active {
  outline: none;
}