.lay-master-set {
    margin-bottom: 65px;
}

.lay-master-set-wrap {
    padding: 60px;
    background-color: #dcf1fa;
    border-radius: 35px;
}

.lay-master-set__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-master-set__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/navi/icon-setting.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}

.lay-master-set__frame {
    width: 100%;
}

.lay-master__table {
    margin: 50px 10%;
    width: 90%;
}

.lay-master-set .get_api_management {
    margin-left: 0;
    margin-bottom: 20px;
}

#set_company_list .lay-master-set .get_api_management button {
    text-align: center;
    display: block;
    width: 180px;
    color: #fff;
    font-size: 16px;
    height: 35px;
    background-color: #008eab;
    border-radius: 10px;
}

.lay-master__table table {
    width: 90%;
    font-size: 16px;
    line-height: 1.5;
}

.lay-master__table table tr th {
    height: 60px;
    text-align: center;
    color: #fff;
    background-color: #777777;
    vertical-align: middle;
}

.lay-master__table table tr td {
    height: 66px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
}

.lay-master__table table tr td.lay-td:nth-of-type(1) {
    width: 10%;
    padding-left: 35px;
}

.lay-master__table table tr td.lay-td:nth-of-type(2) {
    width: 70%;
    text-align: center;
}

.lay-master__table table tr td.lay-td:nth-of-type(3) {
    width: 20%;
}

.lay-master__table span {
    word-break: break-all;
    color: #3C3C3C;
}

.lay-master__table table tr:nth-of-type(2n-1) td {
    background-color: #f0f0f0;
}

.lay-master-set .trun-btn {
    text-align: center;
}

.master_long_btn {
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 300px;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
}

#companyPopup .popup-content {
    height: 250px !important;
}

.mod-master-form__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.mod-master-form__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 160px;
    height: 50px;
    background-color: #777777;
    font-size: 16px;
    color: #fff;
}

.mod-master-form__set dd input[type="text"] {
    width: 500px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
}

.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
}

#companyPopup button {
    outline: none;
}
