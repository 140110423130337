.mod-master-form__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.mod-master-form__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 160px;
    height: 50px;
    background-color: #777777;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
}

.mod-master-form__set dd select {
    width: 500px;
    height: 50px;
    padding-left: 15px;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 400;
    color: #121212;
    background-color: #fff;
    background-image: url('/public/img/common/icon-select.png');
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 14px 9px;
}

.mod-master-form__set .camera_input {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
}

.mod-master-form__set dd input[type="text"] {
    width: 500px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
}

.lay-master-set.tablet #cameraNewData .mod-master-form__set dd select {
    width: 400px;
}

.lay-master-set.tablet #cameraNewData .mod-master-form__set dd input[type="text"] {
    width: 400px;
}

.lay-master-set.ipadpro #cameraNewData .wrapper{
    margin: 0 auto;
    width: 80%;
}

.lay-master-set.ipadpro #cameraNewData .mod-master-form__set dd select {
    width: 400px;
}

.lay-master-set.ipadpro #cameraNewData .mod-master-form__set dd input[type="text"] {
    width: 400px;
}