.cameraInfo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 990px; 
    padding: 10px 0;
    background-color: #f0f0f0;
}

.cameraIndex{
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    color:#fff;
    background: #f6ab00;
    margin-left:20px;
    /* margin-top: 7px; */
    width: 100px;
    height: 40px;
}

.cameraName{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 830px;
    font-size: 15px;
    line-height: 24px;
    padding-right: 15px;
    word-break: break-all;
    margin-left:5px;
}

.lay-search_terms_camera_info {
  margin-bottom: 10px;
}

.lay-search_terms_camera_info .lay-search_terms-wrap_camera_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lay-search_terms__list_camera_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* width: 990px;  */
  width: 100%; 
  padding: 10px 0;
  background-color: #f0f0f0;
}

.lay-search_terms_camera_info .lay-search_terms__list__text_camera_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
  margin-left:45px;
  width: 830px;
}

.lay-list__table__comment_camera_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /*width: 333px;*/
  margin-left: auto;
  /* margin-right: 10px; */
  width: auto;
}

.lay-search_terms_camera_info .lay-list__table__comment_batt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /*width: 333px;*/
  width: 150px;
  margin-top: 10px;
  margin-left: auto;
  align-self: center;
}

.lay-search_terms_camera_info .lay-list__table__comment_camera_info a {
  width: 90px;
}

.battery_text{
  margin-left: 30px;
  width: 150px; 
  height: 28px; 
  /* margin-top: -20px */
}

.battery {
  border: 3px solid #333;
  width: 40px;
  height: 22px;
  padding: 2px;
  border-radius: 4px;
  position: relative;
  margin-left: 30px;
  
  &:before {
    content: '';
    height: 14px;
    width: 4px;
    background: #333;
    display: block;
    position: absolute;
    border-radius: 4px 4px 0 0;
    left: 36px;
    top: 1px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid #fff;
    border-radius: 2px;
  }
}
  
.battery-level {
  background: #30b455;  
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;  
}

.battery-level-alert {
  background: #e81309;  
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;  
}

  .mod-btn__roundborder_camera_info {
    text-align: center;
    display: block;
    color: #008eab;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;
  }
  
  .mod-btn__roundborder_camera_info:hover {
    color: #f6ab00;
    border: #f6ab00 2px solid;
  }
  
  .mod-btn__roundborder_camera_info.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
  }
  
  .mod-btn__roundborder_camera_info.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
    cursor: pointer;
  }

  @media (min-width: 1276px) {
    /* .lay-list__table__comment_batt {
      width: 150px;
    }

    .battery_text {
      width: 150px;
    } */

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info {
      margin-right: 10px;
    }

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info a {
      width: 90px;
    }
  }

  @media (min-width: 981px) and (max-width: 1275px) {
    /* .lay-list__table__comment_batt {
      width: 100px;
    }

    .battery_text {
      width: 100px;
    } */

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info {
      margin-right: 10px;
    }

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info a {
      width: 90px;
    }

    .lay-search_terms_camera_info .lay-search_terms__list__text_camera_info {
      margin-left: 15px;
    }

    .cameraIndex {
      margin-left:5px;
      width: 160px;

    }
  }

  @media (max-width: 980px) {
    /* .lay-list__table__comment_batt {
      width: 70px;
      margin-left: 0px;

    } */

    /* .battery_text {
      width: 100px;
      margin-left: 25px;
    } */

    /* .lay-search_terms_camera_info .lay-search_terms__list__text {
      font-size: 13px;
    } */

    /* .mod-btn__roundborder.moresmall {
      font-size: 10px;
      width: 80%;
    } */

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info {
      margin-right: 10px;
    }

    .lay-search_terms_camera_info .lay-list__table__comment_camera_info a {
      width: 70px;
    }

    .lay-search_terms_camera_info .lay-search_terms__list__text_camera_info {
      margin-left: 15px;
    }

    .cameraIndex {
      margin-left:5px;
      width: 160px;

    }
  }
