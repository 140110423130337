.lay-master-set .breakdown {
    margin-left: 110px;
    font-size: 16px;
    padding-top: 20px;
    color: #666;
}

.lay-master-set-wrap td.tdFirstDate {
    padding-top: 10px;
}

.lay-master-set-wrap td.tdName {
    word-break: break-all;
    width: 60%;
    padding-right: 50px;
    padding-top: 10px;
}

.lay-master-set-wrap.tablet {
    box-sizing: border-box;
}

.lay-master-set.tablet .confirm {
    margin: 0 auto 30px;
    width: 690px;
    height: 150px;
    background: #fff;
    text-align: center;
    display: table;
    vertical-align: middle;
}

.lay-master-set.tablet .breakdown {
    margin-left: 0;
    font-size: 16px;
    padding-top: 20px;
    color: #666;
}

.lay-master-set.tablet .breakdown table {
    margin: 0 auto;
    width: 690px;
}

.lay-master-set.ipadpro .confirm {
    margin: 0 auto 30px;
    width: 610px;
    height: 150px;
    background: #fff;
    text-align: center;
    display: table;
    vertical-align: middle;
}

.lay-master-set.ipadpro .breakdown {
    margin-left: 0;
    font-size: 16px;
    padding-top: 20px;
    color: #666;
}

.lay-master-set.ipadpro .breakdown table {
    margin: 0 auto;
    width: 610px;
}

.lay-master-set.desktop .breakdown table {
    width: 90%;
}

