.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 25px;
}

fieldset {
    border: 2px black solid;
    border-radius: 15px;
    width: 100%;
}
fieldset legend {
    margin-left: 25px;
    width: auto;    /* Reset the width to its natural content size */ 
    font-size: 100%;   
    margin-bottom: 0;
}

#photo_shoot-cam_info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
#photo_shoot-cam_action {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
}

.photo_shoot-cam_info-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0px;
}
.photo_shoot-cam_info-col {
    flex: 1;               /* Allow columns to grow */
    min-width: 250px; 
    margin-right: 15px;    /* Space between columns */
    display: flex;
    width: 50%;
    margin-left: 25px;
}

.right-col {
    margin-left: 70px; /* Remove right margin for the last column */
}

.photo_shoot-cam_info-1col {
    display: flex;
    width: 100%;
    margin-left: 25px;
}
.cam_info-label {
    width: 130px;
    min-width: 120px;
    /* font-weight: bold; */
    word-wrap: break-word;   /* Allow long words to break */
    white-space: normal;     /* Allow line breaks */
}
.right .cam_info-label {
    width: 130px;
}

#cam_info-schedule {
    display: flex; 
    flex-direction: column;  /* Stack items vertically */
    flex-wrap: wrap;
    word-wrap: break-word;   /* Break long words to avoid overflow */
    gap: 10px; 
}

.cam_info-value {
    min-width: 200px;
    text-decoration: underline;
    text-underline-position: under; 
}

.shoot-time-break {
    text-align: center;
    padding-top: 5px;
}
.photo_shoot {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 0px;
    background-color: white;
}

#device_type {
    border: 1px solid #0091a7;
    border-radius: 20%;
    padding: 5px 6px;
    font-size: 110%;
}

#photo-shoot{
    margin-left: 30px;
    width: 100px;
    line-height: 10px;
    height: 36px;
    margin-top: 4px;
    
}

.schedule-value {         
    text-decoration: underline;
    text-underline-position: under; 
}

.schedule_span {
    margin-right: 31px;
    display: inline-block; 
}

.schedule-break {
    display: flex;
    justify-content: center;
    margin-left: -10px;
    align-items: center;
    font-size: 16px;
    color: #555;
    /* height: 40px; */
}

/*-------------- Mobile Portrait */
@media (max-width: 344px) {
    .schedule-value {
        width: 154px;       
    }
    .schedule_span {
        margin-right: 34px;      
    }   
}

@media (min-width: 345px) and (max-width: 539px) {
    .schedule-value {
        width: 200px;
    }
}

@media (min-width: 540px) and (max-width: 576px) {
    .schedule-value {
        width: 330px;       
    }    
}

/*-------------- Mobile Landscape and smaller tablets */
@media (min-width: 577px) and (max-width: 629px) {
    .schedule-value {
        width: 420px;       
    } 
}

/* Ipad Mini */
@media (min-width: 630px) and (max-width: 768px) {
    .schedule-value {
        width: 100%;    
        padding-left: 10px;   
    } 
}

/*-------------- Tablets */
/* Ipad Air */
@media (min-width: 769px) and (max-width: 839px) {
    .schedule-value {
        width: 320px;   
        padding-left: 10px;    
    } 
    .schedule-break {        
        margin-left: -60px; 
    }
}

@media (min-width: 840px) and (max-width: 853px) {
    .schedule-value {
        width: 360px;       
    } 
}

@media (min-width: 854px) and (max-width: 929px) {
    .schedule-value {
        width: 420px;       
    } 
    .schedule-break {        
        margin-left: -40px; 
    }
}

@media (min-width: 930px) and (max-width: 991px) {
    .schedule-value {
        width: 480px;       
    } 
}

/*-------------- Small laptops */
@media (min-width: 992px) and (max-width: 1024px) {
    .schedule-value {
        width: 560px; 
    } 
    .schedule-break {        
        margin-left: -40px; 
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .schedule-value {
        width: 620px; 
    } 
}

/*-------------- Laptops and desktops */
@media (min-width: 1200px) {
    .schedule-value {
        width: 800px;       
    } 
}