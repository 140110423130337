.lay-login-set__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-login-set__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/navi/icon-login.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}

.lay-search__form__col1 {
    justify-content: center;
}

/* .lay-search__form__col1 {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
} */

.react-datepicker__day--outside-month {
    visibility: hidden;
}

.outer-div {
    display: flex;
    align-items: center;
}

.display-main .lay-tab__content {
    margin-top: 20px;
    margin-left: 50px;
}

.display-main .mod-no-data {
    margin-top: 50px;
}

#csv_btn {
    width: 270px;
    margin-right: 90px;
    margin-bottom: 19px;
}

.login-log-header {
    background-color: whitesmoke;
    font-size: 16px !important;
}

.loginInfoTbl tr td {
    padding: 3px 5px !important;
    height: 36px !important;
}

.colNo {
    width: 44px !important;
    vertical-align: middle !important;
}

.colLoginTime {
    width: 224px !important;
    vertical-align: middle !important;
}

.colAuthenClass {
    width: 136px !important;
    vertical-align: middle !important;
}

.colStatus {
    width: 164px !important;
    vertical-align: middle !important;
}

#loginHistory .lay-search__form__col1 {
    display: flex;
}

.colStatus.status_err {
    color: red;
}

#loginHistory .lay-search__btn  {
    margin: 20px auto 0;
}
