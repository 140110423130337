.lay-search_terms-compgraph{
    margin-bottom: 10px;
}

.lay-search_terms-compgraph-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
  
.lay-search_terms-compgraph__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 1200px;
  padding: 15px 0;
  background-color: #f0f0f0;
}

.lay-search_terms-compgraph__list__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 143px;
}

.lay-search_terms-compgraph__list__title img {
  width: 100%;
}

.lay-search_terms-compgraph__list__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 830px;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
}

.lay-list-compgraph__table__comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: 20px;
  width: auto;
}

.checkbox-input {
  margin-left:0; 
}

.checkbox-input:checked {
  color: #09ad7e;
}

.checkbox-parts{
  padding-left: 0;
  margin-right: 20px;
}

.checkbox-parts::before{
  position: absolute;
  top:7px;
  left:0;

}

.checkbox-input:checked + .checkbox-parts::after{
  position: absolute;
  top: 8px;
  left: 5px;
  color: #09ad7e;

}
  
.lay-list__table__comment a {
  width: 90px;
}

@media (min-width: 436px) and (max-width: 991px) {
  .lay-search_terms-compgraph,
  .lay-search_terms-compgraph-wrap {
    width: 100%;
  }

  .lay-search_terms-compgraph__list {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .lay-search_terms-compgraph__list__title img {
    width: 90%;
  }


}

@media (min-width: 992px) and (max-width: 1199px) {

  .lay-search_terms-compgraph,
  .lay-search_terms-compgraph-wrap {
    width: 100%;
  }

  .lay-search_terms-compgraph__list {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
}
  
  
  
  
  