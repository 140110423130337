.lay-camera-set__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-camera-set__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/navi/icon-photo.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}

#cameraChange {
    font-size: 18px;
}

#cameraChange .camera_change_input span, #cameraChange .mod-form__select span, #confirm_message span {
    color: #3C3C3C;
}

.camera-change {
    margin-bottom: 15px;
    font-size: 18px;
}

.camera-change dl dt {
    justify-content: left;
    text-align: left;
    padding-left: 5px;
    background-color: transparent;
    color: black;
    width: 180px;
    font-size: 18px;
}

.camera_change_input {
    display: block;
}

.camera_change_label {
    display: none;
    height: 100%;
}

.camera_change_label span {
    vertical-align: middle;
}

.lay-master-set .checkbox-frame {
    margin-left: 20px;
}

.radio-input:checked {
    color: #09ad7e;
}

.radio-parts {
    padding-left: 2px;
    position: relative;
    margin-right: 20px;
    color: black;
}

.radio-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
}

.radio-input:checked + .radio-parts::after {
    content: "・";
    font-size: 31px;
    display: block;
    position: absolute;
    top: -12px;
    left: -7px;
    width: 6px;
    height: 13px;
}

#meter_count {
    width: 50px;
    height: 24px;
}

#confirm_message {
    margin: 20px 0px;
}

#button_div {
    justify-content: space-around;
    width: 100%;
}

#finish-message {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin: 50px;
    font-size: 18px;
    display: none;
}