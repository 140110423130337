.lay-master-set__form .mod-master-form__set.measure {
    margin-left: 5%;
}

.lay-master-set__form .set_master_name_measure.gauge_name {
    margin-left: 22%;
}

.lay-master-set__form .set_master_name_measure {
    margin-top: 20px;
    margin-bottom: 30px;
}

.lay-master-set__form .set_master_name_measure input[type="text"] {
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
}

dl, dd, p {
    margin-bottom: 0;
}

.lay-master-set__form .measure_limit {
    margin-left: 25%;
    margin-bottom: 5px;
}

/* Device tablet */
.checkbox-parts {
    padding-left: 2px;
    position: relative;
    margin-right: 10px;
}

.checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: #fff;
}

.checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 13px;
    transform: rotate(20deg);
    border-bottom: 2px solid #09ad7e;
    border-right: 2px solid #09ad7e;
}

.lay-master-set.tablet .lay-master-set-wrap {
    padding: 60px 10px;
    background-color: #dcf1fa;
    border-radius: 35px;
}

.lay-master-set__form.tablet .set_master_name_measure.unit {
    margin-left: 50px;
}

.lay-master-set.tablet .set_master_name_measure dd select{
    width: 200px;
}

.lay-master-set.tablet .set_master_name_measure dd > #measure_name_view{
    width: 280px;
}

.lay-master-set__form.tablet .mod-master-form__set.measure {
    margin: 0 3%;
}

.base-time-settings.tablet {
    display: flex;
    flex-wrap: nowrap ;
    justify-content: space-between;
}

.base-time-settings.tablet > #diff_type_panel {
    margin-left: 10px;
}

/* Device ipad pro */
.lay-master-set.ipadpro .lay-master-set-wrap {
    padding: 60px 10px;
    background-color: #dcf1fa;
    border-radius: 35px;
}

.lay-master-set__form.ipadpro .set_master_name_measure.unit {
    margin-left: 40px;
}

.lay-master-set.ipadpro .set_master_name_measure dt {
    width: 120px;
}

.lay-master-set.ipadpro .set_master_name_measure dd > #measure_name_view{
    width: 250px;
}

.lay-master-set__form.ipadpro .mod-master-form__set.measure {
    margin: 0 3%;
}

.base-time-settings.ipadpro {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.base-time-settings.ipadpro dd select {
    width: 230px;
    height: 50px;
    padding-left: 15px;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 400;
    color: #121212;
    background-color: #fff;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAQCAYAAAAFzx/vAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVJJREFUeNq0lLtKA0EUhsdxomjhBUGwECwVwconsLPzySx9Ay00hEC2UjAIllZeVtFGIxI0SJSNgkHUb+CPaGCTzWbzw7fs7pw5/1zOzJDZ3NsyxizCCsyYwegZTuHK8qhDBDkzOOXkUXc8tmEZvmAdRjM2+4BD2IELbxjCAwzDN6zCfEZm93ACu7APr04Nb3AE/nsiQ8NrKCi39zAtw0+owgFMaVmX+igiXySXUFTOx1aDbQv0BVSCPFT6mFlFOUrK+SvXFtiEOwhgUjNdgPGERu9wq5kFyvVPNqZjVSPM6z2puvZzHUYaaqR+phswByMx8U0ZBOoTxo3Ipt2LtHvvElTbsfbSV+8azLZV95MOdlGxHWVTnKfoz/9I/wqK6SrXw43hk4/BtC56rzPtWxlesjT0asigrKX2q3MuGkmT9GLo96sGN7p3rd5rakukHwEGAM48YAza3ro/AAAAAElFTkSuQmCC);
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 14px 9px;
}

/* Device desktop */
.lay-master-set.desktop .set_master_name_measure dd  > #measure_name_view{
    width: 350px;
}

.lay-master-set.desktop .set_master_name_measure dd select{
    width: 250px;
}

.lay-master-set__form.desktop .set_master_name_measure.unit {
    margin-left: 50px;
}

.base-time-settings.desktop {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
