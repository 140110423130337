/* Root variables */
:root {
    --menu-width: 300px;
    --rakusuru-background: #f0f0f0;
    --rakusuru-color: #0091a7;
}

.lay-sidemenu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    width: 300px;
    height: 100vh;
    padding-top: 56px;
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out;
    border-top: #0091a7 6px solid;
}

.lay-sidemenu-wrap {
    position: relative;
    height: 100%;
    min-height: 720px;
}

/* Default menu styles */
/* .menu-container {
    min-width: var(--menu-width);
    max-width: var(--menu-width);
    border-right: 1px solid var(--rakusuru-background);
    max-height: 100vh !important;
    overflow: auto;
    transition: transform 0.3s ease;
    border-top: #0091a7 6px solid;
    position: fixed;
    top: 0;
    left: 0;
} */

.lay-sidemenu .menu-load {
    min-width: var(--menu-width);
    max-width: var(--menu-width);
    border-right: 1px solid var(--rakusuru-background);
    height: 100%;
    overflow: auto;
    position: relative;
    transition: transform 0.3s ease;
    border-top: #0091a7 6px solid;
    position: fixed;
    top: 0;
    left: 0;
}

.lay-sidemenu.hidden {
    transform: translateX(-100%);
}

.lay-sidemenu.open {
    transform: translateX(0);
}

.lay-sidemenu__logo {
    text-align: center;
    margin-bottom: 28px;
}

.lay-sidemenu__logo img {
    width: 172px;
}

.logo-header {
    display: none;
    width: 64px;
    position: fixed;
    top: 10px;
    left: 40%;
    right: 40%;
    z-index: 99;
    text-align: center;
}

.menu-toggle-button {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.menu-icon-small {
    width: 40px !important;
    height: 40px !important;
    margin-right: 15px;
}

.menu-icon-large {
    width: 75px !important;
    height: 75px !important;
    background-size: cover;
    margin-left: -20px;
}

/* Master menu styles */
.lay-sidemenu__set {
    padding: 10px 0 6px 30px;
    border-bottom: 1px solid #fff;
}

.menu-master {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #0091a7;
    margin-bottom: 8px;
}

.menu-master-icon {
    width: 25px;
    height: auto;
    margin-right: 6px;
    vertical-align: middle;
    background-size: 25px auto;
    display: inline-block;
}

#Setting .menu-master-icon {
    width: 32px;
}
.menu-master__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 45px;
    padding-left: 15px;
    color: black;
    font-size: small;
}

.menu-master__item > span {
    font-size: 10px;
}

.menu-master__item > h2 {
    align-items: center;
    font-size: 20px;
    margin: 0 5px;
}

.menu-item-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
    vertical-align: middle;
    background-size: 25px auto;
    display: inline-block;
}

.lay-sidemenu__set .admin {
    margin-bottom: 3px;
    padding-left: 6px;
    width: 100%;
    height: 30px;
}

.lay-sidemenu__set .admin .name {
    margin-left: -2px;
    color: #0091a7;
    font-weight: 400;
    font-size: 14px;
}

.lay-sidemenu__set .menu-item .name {
    margin-left: -2px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
}

.lay-sidemenu__set .admin .circle {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0091a7;
}

.lay-sidemenu__set .ac-link {
    position: relative;
    top: 10px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #0091a7;
    transform: translate(-50%, -50%) rotate(0deg);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.2, 0.6, 0.3, 1.1);
}

.lay-sidemenu__set .ac-link:after {
    content: '';
    position: absolute;
    top: 3.5px;
    left: 6px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    height: 50%;
    width: 2px;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-bottom: 4.5px solid #fff;
}

.lay-sidemenu__set .ac-link.clicked {
    transform: translate(-50%, -50%) rotate(90deg);
    background: #ccc;
}

.lay-sidemenu__set .ac-link.clicked:before {
    width: 0;
}

.lay-sidemenu__set .ac-link.defaultopen {
    position: relative;
    top: 13px;
    width: 15px;
    height: 15px;
    background: #ccc;
    transform: translate(-50%, -50%) rotate(90deg);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.2, 0.6, 0.3, 1.1);
}

.lay-sidemenu__set .ac-link.defaultopen.clicked {
    transform: translate(-50%, -50%) rotate(0deg);
    background: #0091a7;
}

.lay-sidemenu__set .facility-list img {
    width: 20px;
    height: 20px;
}

.lay-sidemenu__set .facility-list .non-ac-link {
    margin-left: -10px;
    margin-right: 10px;
}

.lay-sidemenu__set ul {
    margin-bottom: 10px !important;
}

.lay-sidemenu__set ul li {
    list-style: none;
}

.lay-sidemenu__set .non-menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.lay-sidemenu__set .menu-item__company,
.lay-sidemenu__set .menu-item__setting {
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
}

.lay-sidemenu__set .menu-item__facility {
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
}

.lay-sidemenu__set .menu-item__camera {
    position: relative;
    padding-left: 65px;
    margin-bottom: 12px;
}

.lay-sidemenu__set .menu-item__gauge {
    position: relative;
    padding-left: 75px;
    margin-bottom: 12px;
}

.lay-sidemenu__set .menu-item__measure {
    position: relative;
    padding-left: 85px;
    margin-bottom: 12px;
}

.lay-sidemenu__set .submenu-graph li,
.lay-sidemenu__set .menu-item__setting-sub li {
    margin-bottom: 12px;
}

/* .lay-sidemenu__set ul li button span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    word-break: break-all;
} */

.menu-master__item:hover {
    background-color: #e8f5fe;
    border-radius: 10px;
    color: var(--rakusuru-color);
    transition: color 100ms ease-out;
    text-decoration: none;
}

.menu-master__item--active {
    color: var(--rakusuru-color);
}

.menu-logout {
    display: block;
    width: 100px;
    margin: auto;
    margin-top: 5px;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    background-color: #009933;
    border-color: #00802b;
    color: white;
    border-radius: 5px;
}

.menu-logout:hover {
    text-decoration: none;
    background-color: grey;
    border-color: #00802b;
    color: white;
}

/* .menuIcon {
    height: 100px;
    margin: 0 0 28px 42px;
} */

.menu-item__sub {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s;
}

.menu-item__sub.open {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
}

.logout-button {
    background-color: #008eab;
    color: #fff;
    width: 150px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
    margin: 38px 0 8px 35px;
}

.logout-button:hover {
    background-color: #f6ab00;
}

.lay-sidemenu__copyright {
    height: 64px;
    text-align: center;
}

.lay-sidemenu__copyright img {
    width: 250px;
}

.submenu-graph {
    list-style: none;
    margin-left: 35px;
}

.submenu-graph li span {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
}

.submenu-graph button {
    background: none;
    border: none;
    color: #121212;
    text-decoration: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.submenu-graph button:hover {
    border: none;
    color: #f6ab00;
}

.menu-item__setting-sub {
    margin-left: 40px;
}

.menu-item button, .admin button {
    background: none;
    border: none;
    color: #121212;
    text-decoration: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.menu-item button:hover {
    border: none;
    color: #f6ab00;
}

.lay-sidemenu__set.current {
    background-color: #e1f1f7;
}

.name.active {
    color: #f6ab00;
}

.name.active:hover{
    color: #0091a7;
}

.active-sidemenu {
    background-color: #e1f1f7; /* Blue background color */
}

.menu-item__company,
.menu-item__facility,
.menu-item__camera,
.menu-item__gauge,
.menu-item__measure {
    display: flex;
    align-items: flex-start;
    padding-left: 40px;
}

.ac-link {
    flex-shrink: 0;
}

.name {
    /* flex-grow: 1; */
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
    text-align: left;
}

.plus-icon {
    position: relative;
    top: 10px;
    left: 12px;
    width: 15px;
    height: 15px;
    /* background: #0091a7; */
    background: #ccc;
    transform: translate(-50%, -50%) rotate(0deg);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.2, 0.6, 0.3, 1.1);
    border: 1px solid #ccc;
}

.lay-sidemenu__set .plus-icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    width: 2px;
    background: #fff;
}

.lay-sidemenu__set .plus-icon:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 50%;
    background: #fff;
}

/* Position the plus icon closer to the text */
/* #cameraSettings .menu-item button {
    display: flex;
} */
.plus-minus-outer {
    display: flex;
    margin-left: 6px;
    margin-right: 7px;
}

.menu-load .spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: #82a6dd;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    vertical-align: middle;
    position: absolute;
    top: 5%;
    left: 40%;
}

.menu-item__history {
    margin-left: 15px;
    width: 270px;
    display: flex;
    margin-bottom: 12px;
}

.menu-item__history  img {
    margin-top: 5px;
    width: 20px;
    height: 20px;
}

.menu-item__history .datamange_btn {
    text-align: left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 576px) and (orientation:portrait) {
    .lay-sidemenu.mobile  {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        /* width: 100%; */
        background-color: #ffffff;
        z-index: 99;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .lay-sidemenu.mobile.open {
        transform: translateX(0);
    }

    .menu-toggle-button.mobile {
        display: block;
    }

    .logo-header.mobile {
        display: block;
        width: 55px;
        position: fixed;
        top: 85%;
        left: 1%;
        z-index: 99;
        text-align: center;
    }

    .menu-load.mobile .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top-color: #82a6dd;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        vertical-align: middle;
        position: absolute;
        top: 5%;
        left: 40%;
    }

    .menu-toggle-button.mobile {
        display: inline-block;
        cursor: pointer;
        width: 30px;
        height: 30px; 
        perspective: 1000px; 
        outline: none;
    }
    
    .menu-toggle-button.mobile:focus {
        outline: none;
    }
    
    .menu-toggle-button-inner.mobile {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease;
        transform-style: preserve-3d; 
    }
    
    .menu-toggle-button-inner.mobile.is-open {
        transform: rotateY(180deg); 
    }
    
    .menu-icon.mobile, .close-icon.mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden; 
        z-index: 200;
    }
    
    .menu-icon.mobile {
        background: url('/public/img/common/more.svg') center/contain no-repeat;
    }
    
    .close-icon.mobile {
        background: url('/public/img/common/close.svg') center/contain no-repeat;
        transform: rotateY(180deg);
    }

    .main-container .menu-header.mobile .lay-breadcrumb .option {
        width: 100%;
        overflow: visible;
        z-index: 8;
    }

    .main-container .menu-header.mobile .lay-breadcrumb{
        margin-bottom: 0;
    }

    .user-info.mobile {
        position: absolute;
        top: 60px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        /* border-radius: 4px; */
        padding: 10px;
        overflow: hidden;
        max-height: 0; 
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0; 
        z-index: 10;
    }
    
    .user-info.mobile.visible {
        max-height: 500px; 
        opacity: 1; 
    }
    
    .user-info.mobile.hidden {
        display: none; 
    }

    .logo-user.mobile, .user-info.mobile{
        display: block;
    }

    /* body.desktop .lay-sidemenu {
        min-width: var(--menu-width);
        max-width: var(--menu-width);
        border-right: 1px solid var(--rakusuru-background);
        max-height: 100vh !important;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        border-top: #0091a7 6px solid;
        background-color: #fff;
    }

    body.desktop .menu-load {
        min-width: var(--menu-width);
        max-width: var(--menu-width);
        border-right: 1px solid var(--rakusuru-background);
        height: 100%;
        overflow: hidden;
        padding: 10px 10px;
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        border-top: #0091a7 6px solid;
        z-index: 99;
    }

    body.desktop .logo-user, body.desktop .user-info{
        display: none;
    } */
    
}

@media (max-height: 576px) and (orientation:landscape){
    #Main.mobile{
        width: 100%;
        height: 100%;
        padding-left: 70px;
        /* overflow: auto; */
    }
    
    /* .menu-background-layer.mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 80px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 8;
    } */

    .logo-header.mobile {
        display: block;
        width: 64px;
        position: fixed;
        top: 85%;
        left: 1%;
        z-index: 99;
        text-align: center;
    }

    .lay-sidemenu.mobile.open {
        transform: translateX(0);
    }

    .menu-toggle-button.mobile {
        display: block;
    }

    #cameraSettings.mobile, #graph.mobile, #settings.mobile .menu-item, #settings.mobile .lay-sidemenu__set__title {
        display: none;
    }

    .menu-load.mobile .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top-color: #82a6dd;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        vertical-align: middle;
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .menu-toggle-button.mobile {
        display: inline-block;
        cursor: pointer;
        width: 40px; 
        height: 40px; 
        perspective: 1000px; 
        outline: none;
    }
    
    .menu-toggle-button.mobile:focus {
        outline: none; 
    }
    
    .menu-toggle-button-inner.mobile {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease; 
        transform-style: preserve-3d;
    }
    
    .menu-toggle-button-inner.mobile.is-open {
        transform: rotateY(180deg);
    }
    
    .menu-icon.mobile, .close-icon.mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden; 
    }
    
    .menu-icon.mobile {
        background: url('/public/img/common/more.svg') center/contain no-repeat;
    }
    
    .close-icon.mobile {
        background: url('/public/img/common/close.svg') center/contain no-repeat;
        transform: rotateY(180deg); 
    }

    .logo-user.mobile, .user-info.mobile{
        display: none;
    }
}

/* Mobile portrait */
@media (max-width: 767px) and (orientation: portrait) {
    .lay-sidemenu.mobile {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        /* width: 100%; */
        background-color: #ffffff;
        z-index: 99;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .lay-sidemenu.mobile.open {
        transform: translateX(0);
    }

    .menu-toggle-button.mobile {
        display: block;
    }

    .logo-header.mobile {
        display: block;
        top: 10px;
        left: 43%;
    }

    .menu-background-layer.mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: white;
        opacity: 0.8;
        z-index: 8; /* Behind the menu header */
        border-bottom: 2px solid rgb(0,145,167);
    }

    #cameraSettings.mobile, #graph.mobile, #settings.mobile .menu-item, #settings.mobile .lay-sidemenu__set__title {
        display: none;
    }

    .menu-load.mobile .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top-color: #82a6dd;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        vertical-align: middle;
        position: absolute;
        top: 5%;
        left: 40%;
    }

    .menu-toggle-button.mobile {
        display: inline-block;
        cursor: pointer;
        width: 30px; 
        height: 30px; 
        perspective: 1000px; 
        outline: none;
    }
      
    .menu-toggle-button.mobile:focus {
        outline: none; 
    }
      
    .menu-toggle-button-inner.mobile {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease; 
        transform-style: preserve-3d; 
    }
      
     .menu-toggle-button-inner.mobile.is-open {
        transform: rotateY(180deg); 
    }
      
     .menu-icon.mobile,  .close-icon.mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden; 
    }
      
     .menu-icon.mobile {
        background: url('/public/img/common/more.svg') center/contain no-repeat;
    }
      
     .close-icon.mobile {
        background: url('/public/img/common/close.svg') center/contain no-repeat;
        transform: rotateY(180deg); 
    }

    .main-container .menu-header.mobile .lay-breadcrumb .option {
        width: 100%;
        overflow: visible;
        z-index: 8;
    }

    .main-container .menu-header.mobile .lay-breadcrumb{
        margin-bottom: 0;
    }

    .user-info.mobile {
        position: absolute;
        top: 60px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        /* border-radius: 4px; */
        padding: 10px;
        overflow: hidden;
        max-height: 0; 
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0; 
        z-index: 10;
    }
    
    .user-info.mobile.visible {
        max-height: 500px; 
        opacity: 1; 
    }
    
    .user-info.mobile.hidden {
        display: none; 
    }
}

/* Tablet portrait và landscape */
/* @media (min-width: 768px) and (max-width: 1023px) and (min-height: 577px){ */
    .lay-sidemenu.tablet {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        /* width: 100%; */
        background-color: #ffffff;
        z-index: 99;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .lay-sidemenu.tablet.open {
        transform: translateX(0);
    }

    .menu-toggle-button.tablet{
        display: block;
    }

    .logo-header.tablet {
        display: block;
        top: 10px;
        left: 46%;
    }

    /* .lay-sidemenu {
        min-width: var(--menu-width);
        max-width: var(--menu-width);
        border-right: 1px solid var(--rakusuru-background);
        max-height: 100vh !important;
        overflow: auto;
        position: fixed;
        transition: transform 0.3s ease;
        border-top: #0091a7 6px solid;
        background-color: #ffffff;
    } */

    .menu-load.tablet .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top-color: #82a6dd;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        vertical-align: middle;
        position: absolute;
        top: 5%;
        left: 40%;
    }

    .menu-background-layer.tablet {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: white;
        opacity: 0.8;
        z-index: 8;
        border-bottom: 2px solid rgb(0,145,167);
    }

    .menu-toggle-button.tablet {
        display: inline-block;
        cursor: pointer;
        width: 40px; 
        height: 40px;
        perspective: 1000px; 
        outline: none;
    }
      
    .menu-toggle-button.tablet:focus {
        outline: none; 
    }
      
    .menu-toggle-button-inner.tablet {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s ease;
        transform-style: preserve-3d; 
    }
      
    .menu-toggle-button-inner.tablet.is-open {
        transform: rotateY(180deg); 
    }
      
    .menu-icon.tablet, .close-icon.tablet {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden; 
    }
      
    .menu-icon.tablet {
        background: url('/public/img/common/more.svg') center/contain no-repeat;
    }
      
    .close-icon.tablet {
        background: url('/public/img/common/close.svg') center/contain no-repeat;
        transform: rotateY(180deg);
    }

    .logo-user.tablet, .user-info.tablet{
        display: none;
    }  

    
/* } */

.logo-user, .user-info{
    display: none;
}

/* Laptop và desktop */
/* @media (min-width: 1024px) { */
    .lay-sidemenu.desktop {
        min-width: var(--menu-width);
        max-width: var(--menu-width);
        border-right: 1px solid var(--rakusuru-background);
        max-height: 100vh !important;
        /* overflow: auto; */
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        border-top: #0091a7 6px solid;
        background-color: #fff;
    }

    .menu-load.desktop {
        min-width: var(--menu-width);
        max-width: var(--menu-width);
        border-right: 1px solid var(--rakusuru-background);
        height: 100%;
        overflow: hidden;
        padding: 10px 10px;
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 0.3s ease;
        border-top: #0091a7 6px solid;
        z-index: 99;
    }

    .logo-user.desktop, .user-info.desktop{
        display: none;
    }
/* } */