.lay-search_terms_batteryGraph {
  margin-bottom: 48px;
}

.lay-search_terms_batteryGraph .lay-search_terms-wrap_batteryGraph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lay-search_terms_batteryGraph .lay-search_terms__list_batteryGraph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 698px; 
  padding: 15px 0;
  background-color: #f0f0f0;
}

.lay-search_terms_batteryGraph .lay-search_terms__list__text_batteryGraph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
  margin-left: 20px;
  width: 698px;
}

.lay-search_terms_batteryGraph .lay-search__btn_batteryGraph {
  width: 270px;
  margin: 0 auto;
}

.mod-btn__roundborder_batteryGraph {
  text-align: center;
  display: block;
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
  cursor: pointer;
}

.mod-btn__roundborder_batteryGraph:hover {
  color: #f6ab00;
  border: #f6ab00 2px solid;
}

.batt_modal_view {
  position: absolute;
  width: 340px;
  height: 180px;
  margin: auto;
  /* margin-top: 130px; */
  border: solid 3px #F00;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
  pointer-events: none;
  transform: translateY(-10px);
  background-repeat: no-repeat;
  background-size: 30px 27px;
  background-position: top 30px center;
  overflow: hidden; 
}

.batt_text_view {
  text-align: center;
  line-height: 1.6;
  font-size: 23px;
  margin-top: -15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.batt_measure_unit {
  position: relative;
  top:0px;
  right:3px;
  z-index: 1;
  font-size: 12px;
}

.lay-message {
  width: 980px;
  height: 36px;
  color: #f7f305;
  background-color: #d33232;
    border: 1px solid #d33232;
  text-align: center;
  padding: 4px;
}

.lay-batterygraph {
  margin-bottom: 49px;
}

.lay-batterygraph .lay-battery-estimate{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.lay-batterygraph .lay-battery-estimate span {
  width: 170px; 
  height: 36px; 
  margin-left: 3.5%; 
  background-color: #4C4C4C; 
  color: #FFFFFF; 
  text-align: center; 
  padding: 4px;
  font-size: 16px;
}

.lay-batterygraph .batt_estimate_date{
  width: 100px;
  height: 36px;
  background-color: #fff;
	border: 1px solid #ccc;
  text-align: center;
  padding: 6px;
}

.lay-batterygraph .lay-note{
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 900px;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
  color: #ff1300;
  margin-left: 3.5%;
  margin-bottom: 20px;
}

.lay-batterygraph .lay-graph__btn{
  width: 563px;
  margin: 0 auto;
}

.lay-batterygraph .mod-btn__round_batteryGraph {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 42px;
  width: 50%;
  height: 45px;
  margin-left: 25%;
  background-color: #008eab;
  border-radius: 23px;
  border: #008eab 2px solid;
}

.lay-batterygraph .mod-btn__round_batteryGraph:hover {
  background-color: #f6ab00;
  border: #f6ab00 2px solid;
}

.lay-batterygraph .mod-btn__round_batteryGraph:focus,
.lay-batterygraph .mod-btn__round_batteryGraph:active {
  outline: none;
}

.lay-batterygraph .mod-btn__round_batteryGraph.small {
  height: 38px;
  font-size: 15px;
  line-height: 34px;
  border-radius: 19px;
}

.lay-batterygraph .mod-btn__round_batteryGraph.moresmall {
  height: 30px;
  font-size: 12px;
  line-height: 29px;
  border-radius: 15px;
}

.lay-batterygraph__fig {
  margin-bottom: 0px;
}

.lay-batterygraph__fig .slideshow-container {
  max-width: 1080px;
  position: relative;
  margin: auto;
}

/* .lay-batterygraph__fig .measure_unit {
  position: relative;
  top:0px;
  z-index: 1;
  font-size: 12px;
} */

.batt_chart_area {
  /*width: 1250px;*/ /*20200313*/
  width: 980px;
  height: 505px;
  position: relative;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  overflow-x: auto; 
}

#bchart {
  position: absolute; 
  left: 0; 
  top: 0; 
  
}

.overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

@media (max-width: 1290px) {
  .lay-search_terms_batteryGraph .lay-search_terms__list_batteryGraph {
    width: 90%;
  }

  .lay-batterygraph__fig .slideshow-container {
    width: 630px;
  }

  .batt_chart_area {
    width: 630px;
    height: 400px;

  }

  .lay-batterygraph .lay-note {
    width: 90%;
  }

}

@media (max-width: 900px) {

  .lay-batterygraph__fig .slideshow-container {
    width: 630px;
  }

  .batt_chart_area {
    width: 630px;
    height: 350px;

  }

  .lay-batterygraph .lay-note {
    width: 90%;
  }

} 