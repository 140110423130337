div, span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
}

.overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

.display-main-battery {
  /* width: 990px; */
  /* width: 100%; */
  padding-top: 20px;
	margin: 0 auto;
	margin-bottom: 50px;
}

.display-main-battery .lay-master-set-battery {
    margin-bottom: 35px;
    /* width: 990px; */
}

.lay-master-set-battery .confirm_camera-battery {
  margin-left:100px; 
  margin-bottom:30px; 
  width:690px;
  height: 50px;
  background: #fff;
  text-align: center;
  display: table;
  vertical-align: middle;
}

.lay-master-set-battery .confirm_camera-battery .cell-battery {
  display: table-cell;
  vertical-align: middle;
}

.lay-master-set-battery .confirm_camera-battery span {
  font-size: 20px;
  font-weight:500;
  color: #666;
}

.lay-master-set-wrap-battery {
    padding: 30px;
    background-color: #dcf1fa;
    border-radius: 35px;
}

.lay-master-user__title-battery {
    text-align: center;
    margin-bottom: 29px;
    margin-right:50px;
  }
  
.lay-master-user__title-battery span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 64px;
    background-size: 52px auto;
    color: #008eab;
  }

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
}

.display-main-battery .mod-form__select-battery select {
    width: 250px;
    height: 38px;
    margin-bottom:20px;
    padding-left: 11px;
    padding-right: 25px;
    font-size: 15px;
    line-height: 1.7;
    font-weight: 400;
    color: #121212;
    background-color: #fff;
    background-image: url(/public/img/common/icon-select.png);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 11px 7px;
  }

.mod-form__select-battery hidden {
  display: none;
}

.cameralist hidden {
  display: none;
}

.CameraItem {
  margin-bottom:10px; 

}

.cameralist .pagination {
  text-align:center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;
  height: 60px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

@media (max-width: 767px) {
  .display-main-battery {
    padding-top: 100px; /* Adjust this value based on the height of your header/logo */
  }

  .cameralist {
    width: 100%;
  }

  .lay-master-set-battery {
    width: 100%;
  }

  .lay-master-set-wrap-battery {
    width: 100%; 
    padding: 20px;
  }

  .lay-master-set-battery .confirm_camera-battery {
    width: 80%;
  }
}

@media (max-width: 990px) and (max-height: 560px) and (orientation: landscape) {
  .display-main-battery {
    padding-left: 80px;
    padding-top: 0;
  }

  .lay-master-set-battery {
    width: 100%;
  }

  .lay-master-set-wrap-battery {
    width: 100%; 
    padding: 20px;
  }

  .cameralist {
    width: 100%;
  }

  .lay-master-set-battery .confirm_camera-battery {
    width: 80%;
  }
}

@media (min-width: 436px) and (max-width: 991px) {
  .lay-master-set-battery {
    width: 100%;
  }
  
  .lay-master-set-wrap-battery {
    width: 100%; 
    padding: 20px;
  }

  .cameralist {
    width: 100%;
  }

  .lay-master-set-battery .confirm_camera-battery {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lay-master-set-battery {
    width: 100%;
  }

  .lay-master-set-wrap-battery {
    width: 100%; 
    padding: 20px;
  }

  .cameralist {
    width: 100%;
  }

  .lay-master-set-battery .confirm_camera-battery {
    width: 80%;
  }
}

@media (max-width: 1310px) {
  .lay-master-set-battery .confirm_camera-battery {
    width: 80%;
    margin-left:70px; 
  }
}
