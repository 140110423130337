body {
  margin: 0;
  font-family: 'Noto Sans Japanese', 'Helvetica Neue', 'Meiryo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url("/public/font/NotoSansCJKjp-Light.eot");
  src: url("/public/font/NotoSansCJKjp-Light.eot?#iefix") format("embedded-opentype"),
       url("/public/font/NotoSansCJKjp-Light.woff") format("woff"),
       url("/public/font/NotoSansCJKjp-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url("/public/font/NotoSansCJKjp-Regular.eot");
  src: url("/public/font/NotoSansCJKjp-Regular.eot?#iefix") format("embedded-opentype"),
       url("/public/font/NotoSansCJKjp-Regular.woff") format("woff"),
       url("/public/font/NotoSansCJKjp-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url("/public/font/NotoSansCJKjp-Medium.eot");
  src: url("/public/font/NotoSansCJKjp-Medium.eot?#iefix") format("embedded-opentype"),
       url("/public/font/NotoSansCJKjp-Medium.woff") format("woff"),
       url("/public/font/NotoSansCJKjp-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 600;
  src: url("/public/font/NotoSansCJKjp-Bold.eot");
  src: url("/public/font/NotoSansCJKjp-Bold.eot?#iefix") format("embedded-opentype"),
       url("/public/font/NotoSansCJKjp-Bold.woff") format("woff"),
       url("/public/font/NotoSansCJKjp-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Noto Sans Japanese", "Helvetica Neue", Meiryo, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
