
.lay-search-graph {
    margin-bottom: 49px;
}

.lay-search-graph-wrap {
    padding: 45px;
    background-color: #dcf1fa;
    border-radius: 26px;
}
  
.lay-search-graph__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-search-graph__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/icon-search.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}
  
.lay-search-graph__lead {
    text-align: center;
    font-size: 15px;
    margin-bottom: 26px;
}

#searchNewData-graph {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.lay-search-graph__form1 {
    padding-bottom: 6px;
    border-bottom: #fff 1px solid;
    margin-bottom: 16px;
}

.lay-search-graph__form2 {
    padding-bottom: 16px;
    border-bottom: #fff 1px solid;
    margin-bottom: 36px;
}

.lay-search-graph__form__col1 ul li, .lay-search-graph__form__col2 ul li, .lay-search-graph__form__col3 ul li {
    width: 435px;
}

.lay-search-graph__form__col2 {
    margin-bottom: 24px;
    /* display: flex;
    flex-wrap: wrap; */
}

.lay-search-graph__form__col3 {
    margin-bottom: 16px;
    /* display: flex;
    flex-wrap: wrap; */
}

ul {
    margin-bottom: 0;
}

.lay-search-graph__form__col1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.lay-search-graph__form__col1 ul{
    display: flex;
}


.lay-search-graph__form__col2 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
}

.lay-search-graph__form__col3 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
}

.mod-form-graph__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
}

.mod-form-graph__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 120px;
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
}

dt {
    display: block;
    width: auto;
}

.mod-form-graph__set dl dd {
    width: 315px;
    margin-bottom: 0 !important;
}

.mod-form-graph__text.calendar input[type="text"], .mod-form-graph__text.second-calendar input[type="text"] {
    background-image: url(/public/img/common/icon-calendar.png);
    background-position: right 11px center;
    background-repeat: no-repeat;
    background-size: 18px auto;
}

.mod-form-graph__text input[type="text"] {
    max-width: 315px;
}

.mod-form-graph__text input[type="text"] {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
  }
  
.mod-form-graph__text input[type="text"]::-webkit-input-placeholder {
  color: #9FA0A3;
}

.mod-form-graph__text input[type="text"]::-moz-placeholder {
  color: #9FA0A3;
}

.mod-form-graph__text input[type="text"]:-ms-input-placeholder {
  color: #9FA0A3;
}
.mod-form-graph__text.label input[type="text"] {
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-color: #eee;
}

.react-datepicker {
    border: 1px solid #a6c9e2;
    border-radius: 5px;
    background-color: #f0f7fc;
}
  
.react-datepicker__header {
    background-color: #e1f0f9;
    border-bottom: 1px solid #a6c9e2;
}
  
.react-datepicker__current-month {
    color: #005bb5;
    font-size: 1.2em;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #005bb5;
}
  
.react-datepicker__day--today {
    background-color: #f5d383;
    border-radius: 50%;
    color: #000;
}
  
.react-datepicker__day--selected {
    background-color: #7ec9e2;
    color: #fff;
    border-radius: 50%;
}
  
.react-datepicker__day:hover {
    background-color: #cdeff7;
    color: #000;
}

.highlight-sunday {
    color: #ff0000 !important;     
    font-weight: bold; 
}

.highlight-saturday {
    color: #0000ff !important;
    font-weight: bold; 
}
/* .custom-datepicker {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
} */

.mod-form-graph__set-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    width: 48px;
    height: 38px;
}

.mod-form-graph__select select {
    width: 100%;
    height: 38px;
    /* max-width: 315px; */
    padding-left: 11px;
    padding-right: 25px;
    font-size: 15px;
    line-height: 1.7;
    font-weight: 400;
    color: #121212;
    background-color: #fff;
    background-image: url(/public/img/common/icon-select.png);
    background-position: right 11px center;
    background-repeat: no-repeat;
    background-size: 11px 7px;
}

.lay-search-option__title {
    text-align: center;
    margin-bottom: 29px;
}

.lay-search-option__title span {
  display: inline-block;
  font-size: 30px;
  font-weight: 500;
  padding-left: 50px;
  background: url(/public/img/common/navi/icon-setting.png) left center no-repeat;
  background-size: 40px auto;
  color: #008eab;
}

.mod-btn-graph__round {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  background-color: #008eab;
  border-radius: 23px;
}

.lay-search-graph__btn {
  width: 270px;
  margin: 0 auto;
}

.mod-btn-graph__round:hover {
    background-color: #f6ab00;
    border: #f6ab00 2px solid;
}

.lay-search-graph__btn button:focus, button:active {
  outline: none;
  border: none;
}

.tabs {
  display: inline-block; 
  background-color: #fff;
  width: 315px;
  height: 36px;
  margin: 0 auto;
}

.tab_item {
  width: calc(100%/2);
  /* height: 38px; */
  /*border: 1px solid #ccc ;*/
  background-color: #fff;
  color: #008eab;
  line-height: 38px;
  text-align: center;
  display: block;
  float: left;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
}
.tab_item:hover {
  opacity: 0.75;
  background-color: #f6ab00;
  color: #fff;
}

.tabs input[name="view_type"] {
  display: none;
  margin-top:10px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.tabs input[name="view_type"]:checked + .tab_item {
  background-color: #008eab;
  color: #fff;
}

@media (max-width: 435px) {
    .lay-search-graph-wrap {
        padding: 20px;
    }

    .lay-search-graph__form__col1,
    .lay-search-graph__form__col2,
    .lay-search-graph__form__col3 {
        margin-bottom: 0px;
    }

    .lay-search-graph__form__col1 ul li,
    .lay-search-graph__form__col2 ul li,
    .lay-search-graph__form__col3 ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .mod-form-graph__set dl dt {
        font-size: 12px; 
    }

    .mod-form-graph__text.calendar input[type="text"],
    .mod-form-graph__select select {
        font-size: 12px; 
    }

    .mod-form-graph__text input[type="text"] {
        width: 100%;
        font-size: 12px;
    }

    .mod-form-graph__set-between {
        font-size: 10px; 
    }

    .tabs {
        width: 100%;
    }

    .tab_item {
        font-size: 12px; 
    }
}

/* Tablets */

@media (min-width: 436px) and (max-width: 991px) {
    .lay-search-graph-wrap {
        padding: 20px;
    }

    .lay-search-graph__form__col1,
    .lay-search-graph__form__col2,
    .lay-search-graph__form__col3 {
        margin-bottom: 10px;
    }

    .lay-search-graph__form__col1 ul,
    .lay-search-graph__form__col2 ul,
    .lay-search-graph__form__col3 ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .lay-search-graph__form__col1 ul li,
    .lay-search-graph__form__col2 ul li,
    .lay-search-graph__form__col3 ul li  {
        width: 50%;
        margin-right: 5px;

    }

    .tabs {
        width: 275px;
    }
    
}

@media (min-width: 768px) and (max-width: 768px) {
    .tabs {
        width: 255px;
    }
} 

/* Small laptops */
@media (min-width: 992px) and (max-width: 1023px) {
    .lay-search-graph-wrap {
        padding: 20px;
    }

    .lay-search-graph__form__col1 {
        display: flex;
        align-items: center;
    }

    .lay-search-graph__form__col2,
    .lay-search-graph__form__col3 {
        margin-bottom: 10px;
    }
    
    .lay-search-graph__form__col1 ul {
        display: flex;
        list-style: none; 
        padding: 0;
        margin: 0;
    }

    .lay-search-graph__form__col2 ul,
    .lay-search-graph__form__col3 ul {
        display: flex;
        flex-wrap: nowrap;
    }

    .lay-search-graph__form__col1 ul li:last-child {
        margin-right: 0;
    }

    .lay-search-graph__form__col1 ul li {
        /* width: 315px; */
        margin-bottom: 10px;
        margin-right: 5px;
    }

    .lay-search-graph__form__col2 ul li,
    .lay-search-graph__form__col3 ul li {
        /* width: 315px; */
        margin-right: 5px;
    }

    .custom-datepicker {
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .mod-form-graph__set dl {
        display: flex; 
        align-items: center; 
        margin: 0; 
    }

    .mod-form-graph__set dl dt {
        width: 100px; 
        height: 32px;
        font-size: 14px; 
    }

    .mod-form-graph__set dl dd {
        width: 100%; 
        height: 32px;
        margin-bottom: 10px;
    }

    .mod-form-graph__text.calendar {
        flex: 1; 
        max-width: none; 
    }

    .mod-form-graph__text.calendar input[type="text"],
    .mod-form-graph__select select {
        width: 100%; 
        /* max-width: none;  */
        height: 32px; 
        font-size: 14px; 
    }

    .mod-form-graph__text input[type="text"] {
        width: 100%;
        height: 32px;
        font-size: 14px;
    }

    .mod-form-graph__set-between {
        font-size: 10px; 
        width: 40px; 
        height: 32px; 
    }

    .tabs {
        width: 300px;
        height: 32px;
        margin: 0 auto;
    }

    .tab_item {
        height: 32px;
        font-size: 14px; 
    }

}

@media (min-width: 1024px) and (max-width: 1370px) {
    .lay-search-graph-wrap {
        padding: 20px;
    }

    .lay-search-graph__form__col1,
    .lay-search-graph__form__col2,
    .lay-search-graph__form__col3 {
        margin-bottom: 10px;
    }

    .lay-search-graph__form__col1 ul,
    .lay-search-graph__form__col2 ul,
    .lay-search-graph__form__col3 ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .lay-search-graph__form__col1 ul li,
    .lay-search-graph__form__col2 ul li,
    .lay-search-graph__form__col3 ul li  {
        width: 50%;
        margin-right: 5px;

    }

    .tabs {
        width: 315px;
    }
}

@media (min-width: 1024px) and (max-width: 1024px) {
    .tabs {
        width: 240px;
    }
}


/* Laptops and desktops */
/* @media (min-width: 1200px) {

    .lay-search-graph__form__col1 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
    }

    .lay-search-graph__form__col1 ul{
        display: flex;
    }

    .custom-datepicker {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

} */