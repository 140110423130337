.overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

.mod-btn-compgraph__roundborder {
  text-align: center;
  display: block;
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
}

.mod-btn-compgraph__roundborder:hover {
  color: #f6ab00;
  border: #f6ab00 2px solid;
}

.mod-btn-compgraph__roundborder:focus,
.mod-btn-compgraph__roundborder:active {
  outline: none;
}

.mod-btn-compgraph__roundborder.small {
  height: 38px;
  font-size: 15px;
  line-height: 34px;
  border-radius: 19px;
}

.mod-btn-compgraph__roundborder.moresmall {
  height: 30px;
  width: 10%;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 29px;
  border-radius: 15px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .display-main {
    padding-top: 100px;
  }

  .list {
    width: 100%;
  }
}

@media (max-width: 990px) and (max-height: 560px) and (orientation: landscape) {
  .display-main {
    padding-left: 80px;
    padding-top: 0;
  }

  .list {
    width: 100%;
  }
}
@media (min-width: 436px) and (max-width: 991px) {
  .list {
    width: 100%;
  }

  .mod-btn-compgraph__roundborder.moresmall {
    width: 20%;

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .searchCondition {
    width: 100%; 
  }

  .list {
    width: 100%;
  }
}
 