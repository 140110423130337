.lay-master__table .sortPrev {
    color: #008eab;
}

.lay-master__table .sortPrev:hover {
    cursor: pointer;
    color: #f6ab00;
}

.lay-master__table .sortNext {
    color: #008eab;
}

.lay-master__table .sortNext:hover {
    cursor: pointer;
    color: #f6ab00;
}
