.lay-master-set__form .user_input {
    text-align: left;
    margin-left: 25%;
    margin-top: 20px;
}

.lay-master-set__form .user_input span {
    font-weight: 500;
    color: #666;
}

.lay-master-set__form .user_input input[type="text"] {
    width: 80%;
    height: 40px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 15px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ddd;
}

.lay-master-set .user-btn {
    width: 100%;
    /* text-align: center; */
    padding-left: 330px;
}

.lay-master-set .user-btn button {
    width: 250px;
}

.lay-master-set.tablet .user-btn {
    width: 100%;
    text-align: center;
    padding-left: 0;
}

.lay-master-set.tablet #userData .wrapper{
    margin: 0 auto;
    width: 80%;
}

.lay-master-set.tablet .lay-master-set__title {
    margin-right: 0;
}

.lay-master-set.ipadpro .user-btn {
    width: 100%;
    text-align: center;
    padding-left: 0;
}

.lay-master-set.ipadpro .lay-master-set__title {
    margin-right: 0;
}

.lay-master-set.ipadpro #userData .wrapper{
    margin: 0 auto;
    width: 80%;
}