.lay-master-set .confirm {
    margin-left: 100px;
    margin-bottom: 30px;
    width: 690px;
    height: 150px;
    background: #fff;
    text-align: center;
    display: table;
    vertical-align: middle;
}

.lay-master-set .confirm .cell {
    display: table-cell;
    vertical-align: middle;
}

.lay-master-set .confirm span {
    font-size: 20px;
    font-weight: 500;
    color: #666;
}

.lay-master-user__title {
    text-align: center;
    margin-bottom: 29px;
}

.lay-master-user__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 64px;
    background-size: 52px auto;
    color: #008eab;
}