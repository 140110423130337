.lay-search_terms__list {
    display: flex;
    flex-wrap: wrap; /* Allows elements to wrap into new rows */
    justify-content: flex-start; /* Aligns elements to the start of the row */
    align-items: center; /* Vertically centers elements in each row */
    gap: 10px; /* Adds space between elements */
}

fieldset {
    border: 2px black solid;
    border-radius: 15px;
    width: 100%;
}
fieldset legend {
    margin-left: 25px;
    width: auto;    
    font-size: 100%;   
}

.shoot_photo {
    width: 100%;
    /* padding: 20px; */
}

.photo_shoot-content {
    /* margin-top: -15px; */
    margin-bottom: 10px;
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: space-between; /* Position button to the right */
}

#photo_shoot-message {
    margin: 10px 20px;
}

.photo_shoot-status-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0px;
}

.photo_shoot-status-col {
    flex: 1;               /* Allow columns to grow */
    min-width: 250px; 
    margin-right: 15px;    /* Space between columns */
    display: flex;
    width: 50%;
    margin-left: 25px;
}

.photo_shoot-button {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    gap: 10px; /* Space between button and loader image */
}

.mod-btn__round.photo-shoot-btn:disabled {
    background-color: rgb(128, 128, 128); /* Gray background for disabled button */
    cursor: not-allowed; 
}

.status-value,
.result-value {
  color: rgb(14, 6, 8);
}

#photo_shoot-message, #debug_lock_interval {
    flex: 1 1 auto; /* Allows flexible growth and shrinkage */
    min-width: 200px; /* Minimum width for elements */
  }

#photo-shoot{
    margin-left: 30px;
    width: 100px;
    line-height: 10px;
    height: 36px;
    margin-top: 4px;    
}

#photo_shoot-button {
    display: flex;
    width: 200px;
    align-items: center; /* Aligns items vertically in the center */    
}

.waiting-img {
    background-image: url(/public/img/common/loader.gif);
    background-repeat: no-repeat;
    background-size: contain; /* Adjust size to fit */
    width: 60px;  /* Set appropriate width */
    height: 60px; /* Set appropriate height */
}

#debug_lock_interval {
    display: flex;
    margin-right: 40px;    
    align-self: center;
}
#lock_interval {
    border: 1px solid black;
    width: 70px;
    text-align: right;
    padding: 3px;
    color: black;
}
#set_lock_interval {
    width: 70px;
    height: 36px;
    line-height: 0px;
}
#debug_label {
    margin-right: 10px;
    align-self: center;
}

#debug_label label {
    padding-left: 5px;
}

/*-------------- Mobile Portrait */
@media (max-width: 344px) {
   
}

@media (min-width: 345px) and (max-width: 539px) {
    
}

@media (min-width: 540px) and (max-width: 576px) {
    
}

/*-------------- Mobile Landscape and smaller tablets */
@media (min-width: 577px) and (max-width: 629px) {
    
}

/* Ipad Mini */
@media (min-width: 630px) and (max-width: 768px) {
    
}

/*-------------- Tablets */
/* Ipad Air */
@media (min-width: 769px) and (max-width: 839px) {
   
}

@media (min-width: 840px) and (max-width: 853px) {
   
}

@media (min-width: 854px) and (max-width: 929px) {
   
}

@media (min-width: 930px) and (max-width: 991px) {
    
}

/*-------------- Small laptops */
@media (min-width: 992px) and (max-width: 1024px) {
   
}

@media (min-width: 1025px) and (max-width: 1199px) {
   
}

/*-------------- Laptops and desktops */
@media (min-width: 1200px) {
    
}