.lay-notice {
  margin: 180px 20%;
  width: 60%;
  text-align: center;
}

.lay-notice img{
  margin-bottom: 50px;
  width: 200px;
}

.lay-notice .main_message {
  margin-bottom: 10px;
  color:red;
  font-size: 22px;
  font-weight:400;
}

.lay-notice .sub_message {
  margin-bottom: 50px;
}

.lay-notice .button {
  text-align: center;
  width: 30%;
  margin-left: 35%;
}

.mod-btn__round moresmall redirect_btn:focus,
.mod-btn__round moresmall redirect_btn:active {
  outline: none;
}

@media (max-width: 576px) {

  .lay-notice .button {
    text-align: center;
    width: 70%;
    margin-left: 15%;

  }
}

@media (max-height: 576px) {
  .lay-notice {
    margin: 30px 20%;
    width: 60%;
    text-align: center;
  }

}
