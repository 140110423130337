.lay-search_terms-transgraph{
    margin-bottom: 10px;
}

.lay-search_terms-transgraph-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
  
.lay-search_terms-transgraph__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 1200px;
  padding: 15px 0;
  background-color: #f0f0f0;
}

.lay-search_terms-transgraph__list__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 143px;
}

.lay-search_terms-transgraph__list__title img {
  width: 100%;
}

.lay-search_terms-transgraph__list__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 830px;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
}

.lay-list-transgraph__table__comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: 20px;
  width: auto;
}
  
.lay-list-transgraph__table__comment a {
  width: 90px;
}

.mod-btn-transgraph__roundborder {
  text-align: center;
  display: block;
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
}

.mod-btn-transgraph__roundborder:hover {
  color: #f6ab00;
  border: #f6ab00 2px solid;
}

.mod-btn-transgraph__roundborder:focus,
.mod-btn-transgraph__roundborder:active {
  outline: none;
}

.mod-btn-transgraph__roundborder.small {
  height: 38px;
  font-size: 15px;
  line-height: 34px;
  border-radius: 19px;
}

.mod-btn-transgraph__roundborder.moresmall {
  height: 30px;
  width: 90px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 29px;
  border-radius: 15px;
  cursor: pointer;
}

@media (min-width: 436px) and (max-width: 991px) {
  .lay-search_terms-transgraph,
  .lay-search_terms-transgraph-wrap {
    width: 100%;
  }

  .lay-search_terms-transgraph__list {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .lay-search_terms-transgraph__list__title img {
    width: 90%;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .lay-search_terms-transgraph,
  .lay-search_terms-transgraph-wrap {
    width: 100%;
  }

  .lay-search_terms-transgraph__list {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
}