.container-datamanage {
    width: 990px;
    /* padding-top: 38px; */
    padding-top: 15px;
    padding-bottom: 38px;
    margin: 0 auto;
  }

.lay-search {
    margin-bottom: 49px;
}

  .lay-search-wrap {
    padding: 45px;
    background-color: #dcf1fa;
    border-radius: 26px;
}
  
.lay-search__title {
    text-align: center;
    margin-bottom: 22px;
}

.lay-search__title span {
    display: inline-block;
    font-size: 29px;
    font-weight: 500;
    padding-left: 48px;
    background: url(/public/img/common/icon-search.png) left center no-repeat;
    background-size: 39px auto;
    color: #008eab;
}
  
.lay-search__lead {
    text-align: center;
    font-size: 15px;
    margin-bottom: 26px;
}

.lay-search__form {
    padding-bottom: 6px;
    border-bottom: #fff 1px solid;
    margin-bottom: 36px;
}

.mod-form__set dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
}

.mod-form__set dl dt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 120px;
    height: 38px;
    background-color: #777777;
    font-size: 15px;
    font-weight: normal !important;
    color: #fff;
    flex-shrink: 0;
}

dt {
    display: block;
    width: auto;
}

.mod-form__set dl dd {
    width: 315px;
    margin-bottom: 0 !important;
}

.mod-form__text.calendar input[type="text"], .mod-form__text.second-calendar input[type="text"] {
    background-image: url(/public/img/common/icon-calendar.png);
    background-position: right 11px center;
    background-repeat: no-repeat;
    background-size: 18px auto;
}

.mod-form__text input[type="text"] {
    max-width: 315px;
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
}

/* Custom styles for react-datepicker */
.react-datepicker {
    border: 1px solid #a6c9e2;
    border-radius: 5px;
    background-color: #f0f7fc;
}
  
.react-datepicker__header {
    background-color: #e1f0f9;
    border-bottom: 1px solid #a6c9e2;
}
  
.react-datepicker__current-month {
    color: #005bb5;
    font-size: 1.2em;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #005bb5;
}
  
.react-datepicker__day--today {
    background-color: #f5d383;
    border-radius: 50%;
    color: #000;
}
  
.react-datepicker__day--selected {
    background-color: #7ec9e2;
    color: #fff;
    border-radius: 50%;
}
  
.react-datepicker__day:hover {
    background-color: #cdeff7;
    color: #000;
}

.highlight-sunday {
    color: #ff0000 !important;     
    font-weight: bold; 
}

.highlight-saturday {
    color: #0000ff !important;
    font-weight: bold; 
}
  
/* .custom-datepicker {
    width: 315px;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 11px;
    outline: 0;
    background-color: #fff;
} */

.mod-form__set-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    width: 48px;
    height: 38px;
}

.lay-search__form__col2 {
    margin-bottom: 24px;
}

ul {
    margin-bottom: 0;
}

.lay-search__form__col2 ul {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    /* list-style: none; */
}


.lay-search__form__col1 ul li, .lay-search__form__col2 ul li {
    width: 435px;
}

.mod-form__select select {
    width: 100%;
    height: 38px;
    padding-left: 11px;
    padding-right: 25px;
    font-size: 15px;
    line-height: 1.7;
    font-weight: 400;
    color: #121212;
    background-color: #fff;
    background-image: url(/public/img/common/icon-select.png);
    background-position: right 11px center;
    background-repeat: no-repeat;
    background-size: 11px 7px;
}

#facility > option {
    padding-right: 10px !important;
}

.lay-search__btn {
    width: 270px;
    margin: 0 auto;
}

.lay-search__btn button:focus, button:active {
    outline: none;
    border: none;
}

a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
}

input[type='submit'], input[type='button'], label, button, select {
    cursor: pointer;
}

.lay-search_terms {
    margin-bottom: 48px;
}

.lay-search_terms-wrap, .lay-search_terms__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.lay-search_terms-wrap {
    align-items: center;
}

.lay-search_terms__list {
    background-color: #f0f0f0;
    padding: 15px 0;
    width: 698px;
}

.lay-search_terms__list__title {
    align-items: center;
    display: flex;
    width: 143px;
}

.lay-search_terms__list__title img {
    width: 100%;
}

img {
    background-color: transparent;
    vertical-align: top;
}

.lay-search_terms__list__text {
    align-items: center;
    display: flex;
    font-size: 15px;
    line-height: 24px;
    padding-right: 15px;
    width: 536px;
    word-break: break-all;
}

.lay-search_terms__btn {
    clear: both;
    width: 270px;
}

.lay-search_terms__btn > ul > li.current {
    display: block;
}

.lay-search_terms__btn > ul > li {
    display: none;
}

.lay-tab {
    margin-bottom: 45px;
}

.lay-tab__content {
    clear: both;
}

.lay-tab__content .pager-frame {
    margin-bottom: 20px;
    width: 100%;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 25px;
}

.lay-tab__content .result_num {
    margin-bottom: 20px;
}

.lay-tab__head {
    float: right;
    width: 480px;
    /* margin-bottom: 30px; */
    margin-bottom: 10px;
}

.lay-tab__head ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
}

.lay-tab__head ul li.current {
    color: #fff;
    display: block;
    border-color: rgba(0, 142, 171, 0);
    background: url(/public/img/common/bg-current.png);
}

.lay-tab__head ul li:nth-of-type(1) {
    border-radius: 19px 0 0 19px;
}

.lay-tab__head ul li {
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    width: 240px;
    height: 38px;
    cursor: pointer;
    border: #008eab 2px solid;
    color: #008eab;
}
.lay-tab__head ul li:nth-of-type(2) {
    border-radius: 0 19px 19px 0;
}

.pager .pagination {
    text-align: left;
}

.pager .pagination .pagepre, .pager .pagination .pagenext {
    width: 100px;
    height: 25px;
    font-size: 14px; 
    border-radius: 50px;
    background-color: #fff;
    border: #008eab 2px solid;
}

.pager .pagination li {
    display: inline;
    margin: 0 2px;
    padding: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    position: relative;
    border-radius: 50px;
    background-color: #fff;
    border: #008eab 2px solid;
}

.pager .pagination li.pagepre div, .pager .pagination li.pagenext div {
    width: 100%;
    text-align: center;
}

.pager .pagination li div {
    background: none;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    display: table;
    color: #008eab;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.pager .pagination li div span {
    display: table-cell;
    vertical-align: middle;
}

.pager .pagination li.page-number-3 div span{
    padding-bottom: 2px;
}

.pager .pagination li div:hover, .pager .pagination li div.active {
    color: #fff;
    background-color: #f6ab00;
    border-radius: 50px;
    cursor: pointer;
}

.page-number-3 {
    width: 27px !important;
}

.page-number-4 {
    width: 29px !important;
}

.page-number-3 span{
    font-size: 12px !important;
}

.page-number-4 span {
    font-size: 11px;
}

.lay-tab__content > ul > li.lay-li.current {
    display: block;
}

.lay-tab__content > ul > li.lay-li {
    display: none;
}

.lay-list__horizontal {
    margin-bottom: 10px;
}

.lay-list__horizontal ul {
    overflow: hidden;
    display: flex;
}

.lay-list__horizontal ul li {
    float: left;
    width: 180px;
    padding: 30px 0 23px;
    border-top: #008eab 4px solid;
    margin-right: 22px;
}

.lay-list__horizontal ul li:last-child {
    margin-right: 0;
}

.lay-list__horizontal-error {
    background-color: #fbdddd;
    background-image: url(/public/img/common/icon-caution.png);
    background-position: 4px 8px;
    background-size: 23px auto;
    background-repeat: no-repeat;
    border-top-color: #e62e2e !important;
}

.lay-list__horizontal__date {
    text-align: center;
    font-size: 14px;
    margin-bottom: 6px;
    word-break: break-all;
}

.lay-list__horizontal__name {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
    word-break: break-all;
}

.lay-list__horizontal__checkbox {
    margin-bottom: 5px;
}

.lay-list__horizontal__checkbox .checkbox-parts {
    padding-left: 2px;
    position: relative;
    top: -5px;
    margin-right: 20px;
}

.lay-list__horizontal__checkbox .checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: #fff;
}

.lay-list__horizontal__checkbox .checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 13px;
    transform: rotate(20deg);
    border-bottom: 2px solid #09ad7e;
    border-right: 2px solid #09ad7e;
}

.lay-list__horizontal__pct {
    text-align: center;
    margin-bottom: 18px;
}

.lay-list__horizontal__pct img {
    width: 100%;
    height: 200px;
}

.mod-form__num {
    position: relative;
    margin-top: 8px;
}

.mod-form__num input[type="text"] {
    width: 100%;
    height: 27px;
    text-align: right;
    padding-right: 3.7em;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.7;
    outline: 0;
    background-color: #fff;
    border: #d9d9d9 1px solid;
}

.mod-form__num-unit {
    position: absolute;
    overflow: hidden;
    display: block;
    top: 0px;
    right: 9px;
    font-size: 14px;
    line-height: 27px;
    width: 45px;
    height: 27px;
    text-align: right;
}

.diff {
    border: 1px solid;
    background-color: #b3ffff;
    margin-top: 5px;
}

.lay-list__horizontal__para__num {
    position: relative;
    width: 100%;
    height: 27px;
    text-align: right;
    padding-right: 3.7em;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.7;
    margin-top: 8px;
}

.lay-list__horizontal__para__text {
    position: absolute;
    overflow: hidden;
    display: block;
    top: 0px;
    left: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    height: 27px;
    text-align: left;
}

.lay-list__horizontal__para__num-unit {
    position: absolute;
    overflow: hidden;
    display: block;
    top: 0px;
    right: 9px;
    font-size: 14px;
    line-height: 27px;
    width: 45px;
    height: 27px;
    text-align: right;
}

.lay-list__horizontal-error .lay-list__horizontal__para__num-unit {
    color: #121212;
}

.lay-list__horizontal__comment {
    padding: 15px 6px 0;
    border-top: #fff 1px solid;
    margin-top: 15px;
}

.lay-list__horizontal__comment__title {
    text-align: center;
    font-size: 15px;
    margin-bottom: 15px;
}

.lay-list__horizontal_slider {
    width: 100%;
}

.lay-list__horizontal_slider label {
    display: flex;
    align-items: center;
}

.slider {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #e6ebf1;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0 5px;
}

.mod-form__textarea textarea {
    width: 100%;
    height: 200px;
    font-size: 18px;
    line-height: 1.6;
    padding: 20px;
    background-color: #fff;
    resize: none;
    border: #d9d9d9 1px solid;
}

textarea {
    resize: vertical;
}

input, button, select, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
}

.lay-list__horizontal__btn {
    /* padding: 19px 15px 0; */
    padding: 20px 20px 0;
}

.lay-list__horizontal__btn button:focus {
    outline: none;
}

.lay-list__table table {
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
}

table {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
}

.lay-list__table table tr th {
    height: 45px;
    text-align: center;
    color: #fff;
    background-color: #777777;
    vertical-align: middle;
    word-break: keep-all;
}

th {
    font-weight: normal;
    text-align: left;
}

td, th {
    vertical-align: top;
}

.lay-list__table table tr td:nth-of-type(1) {
    width: 86px;
    text-align: center;
}

.lay-list__table table tr td:nth-of-type(2) {
    width: 86px;
    text-align: center;
}

.lay-list__table table tr td:nth-of-type(7) {
    width: 70px;
}

.lay-list__table table tr td:nth-of-type(8) {
    width: 70px;
}

.lay-list__table table tr td:nth-of-type(9) {
    width: 200px;
    text-align: left;
}

.lay-list__table__comment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 333px;
    width: 280px;
}

.lay-list__table table tr td {
    height: 50px;
    vertical-align: middle;
    text-align: center;
    word-break: break-word;
}

td, th {
    vertical-align: top;
}

.lay-list__table table tr:nth-of-type(2n) td {
    background-color: #f0f0f0;
  }

.mod-btn__round.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
}

.mod-btn__round.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
}

.mod-btn__round {
    text-align: center;
    display: block;
    color: #fff;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    background-color: #008eab;
    border-radius: 23px;
}

.mod-btn__roundborder.small {
    height: 38px;
    font-size: 15px;
    line-height: 34px;
    border-radius: 19px;
}

.mod-btn__roundborder {
    text-align: center;
    display: block;
    color: #008eab;
    font-size: 17px;
    line-height: 42px;
    width: 100%;
    height: 45px;
    border-radius: 23px;
    background-color: #fff;
    border: #008eab 2px solid;
}

.mod-btn__roundborder:hover {
    color: #f6ab00;
    border: #f6ab00 2px solid;
}

a, input[type="button"], button {
    -webkit-transition: ease .1s;
    transition: ease .1s;
}

.mod-btn__round:hover {
    background-color: #f6ab00;
}

.mod-btn__roundborder.moresmall {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
    border-radius: 15px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 300;
    width: 100%;
    height: 100%;
    background-color: rgba(192, 192, 192, 0.3);
    z-index: 998; 
    display: none; 
}

.overlay.active {
    display: block;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: none; 
}

.spinner.active {
    display: block; 
    width: 24px;
    height: 24px;
    background: url(/public/img/common/loader.gif)
}

.spinner-count {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: #212529;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    vertical-align: middle;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.mod-no-data {
    margin: 0px;
    background: #e83e8c;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 18px;
}

.react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
}

.mod-form__set dl:nth-of-type(2) {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
}

.pager .pagination li div.disable-hover:hover {
    pointer-events: none; 
    background-color: inherit; 
    border: none;
  
}

.custom-header {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    gap: 5px;
    font-size: 16px;
    padding: 5px;
    margin: 0 2px;
}

.custom-header button {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 15px;
    outline: none;
    color: #005bb5;
}

.custom-header span {
    font-weight: bold;
    color: #005bb5;
}

/* Mobile Portrait */
@media (max-width: 576px) {
    .container-datamanage.mobile {
        width: 100%;
        padding: 5px;
    }

    .lay-search-wrap.mobile {
        padding: 20px;
    }

    .lay-search__form__col1.mobile,
    .lay-search__form__col2.mobile {
        margin-bottom: 0;
    }

    /* .lay-search__form__col1 ul {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    } */

    .lay-search__form__col1.mobile ul li{
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col2.mobile ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col1.mobile ul li:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lay-search__form__col1.mobile ul > li:first-child {
        margin-bottom: 0;
    }    

    .lay-search__form__col2.mobile ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* list-style: none; */
    }

    .lay-search_terms__btn.mobile {
        width: 100%;
    }

    .custom-datepicker.mobile {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .lay-search__form__col1.mobile ul {
        display: flex;
        flex-direction: column;
    }

    .lay-search__form__col1.mobile li {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mod-form__set.mobile {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .mod-form__set-between.mobile {
        height: auto;
    }

    .custom-datepicker.mobile {
        width: 100%; 
    }

    .lay-search_terms__list.mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .lay-search_terms__list.mobile img {
        height: 50px;
        padding-right: 10px;
    }
    
    .lay-search_terms__btn.mobile, .pager-frame.mobile .lay-tab__head.mobile{
        display: none;
    }

    .lay-list__horizontal.mobile li{
        width: 100%;
    }

    #dispDataMnageData.mobile {
        margin: 0 auto;
        padding: 10px;
    }

    .lay-tab.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__content.mobile {
        clear: both;
    }

    .lay-tab__content.mobile .pager-frame {
        margin-bottom: 20px;
        width: 100%;
    }

    .flex-container.mobile {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 15px;
        flex-direction: column;
    }

    .lay-tab__content.mobile .result_num {
        margin-bottom: 10px;
        text-align: center;
    }

    .lay-tab__head.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__head.mobile ul {
        display: flex;
        justify-content: center;
    }

    .lay-tab__head.mobile ul li {
        width: 45%;
        font-size: 14px;
        line-height: 30px;
    }

    .lay-tab__head.mobile ul li.current {
        background-color: #008eab;
        color: #fff;
    }

    .lay-list__horizontal.mobile {
        margin-bottom: 10px;
        width: 100%;
    }

    .lay-list__horizontal.mobile ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .lay-list__horizontal__date.mobile {
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__name.mobile {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__checkbox.mobile {
        margin-bottom: 5px;
        text-align: center;
        display: none;
    }

    .lay-list__horizontal__pct.mobile{
        aspect-ratio: 1/1;
        border: 1px solid #ccc;
    }

    .lay-list__horizontal__pct.mobile img {
        aspect-ratio: 1/1;
        height: auto;
        min-height: 300px;
        object-fit: fill;
        max-width: 100%;
        /* border: 1px solid #ccc; */
        /* border-radius: 5px; */
    }

    .lay-list__horizontal__comment.mobile {
        padding: 10px 5px;
        margin-top: 10px;
        text-align: center;
    }

    .mod-form__textarea.mobile textarea {
        width: 100%;
        height: 150px;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .lay-search__form.mobile .second-calendar {
        text-align: right;
    }     

    .lay-list__horizontal__btn.mobile {
        padding: 10px;
        text-align: center;
    }

    .mod-btn__round.mobile.small {
        height: 34px;
        font-size: 14px;
        line-height: 32px;
        border-radius: 15px;
    }

    .lay-list__horizontal.mobile ul li {
        width: 100%;
        padding: 10px 0;
        border-top: #008eab 2px solid;
        margin-right: 0;
    
    }

    .mod-form__set-between.mobile {
        width: 100%;
        margin: 0 10px 0 10px;
    }

    .mod-form__text.mobile input[type="text"] {
        /* min-width: 225px;
        max-width: 315px; */
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    #searchNewData.mobile {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .camera_info_photoshoot.mobile {
        display: none !important;
    }

    .mod-form__set.mobile .class_hidden{
        visibility: hidden;
    }

    .pager.mobile .pagination .pagepre,
    .pager.mobile .pagination  .pagenext{
      display: none; 
    }
  
    .pager.mobile .pagination .pagepre_icon,
    .pager.mobile .pagination .pagenext_icon {
      display: inline; 
    }

    .lay-search_terms.mobile {
        margin-bottom: 0;
    }

    #Main.mobile #Contents .lay-breadcrumb .option {
        display: none;
    }
}

@media (max-height: 576px) and (orientation:landscape){
    #Main.mobile #Contents {
        /* padding-top: 38px; */
        padding-top: 15px;
        padding-bottom: 0;
        margin: 0 auto;
    }

    .container-datamanage.mobile {
        width: 100%;
        padding: 15px 15px 0;
    }
    /* .menu-background-layer.mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 80px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 8;
    } */

    .logo-header.mobile {
        display: block;
        width: 64px;
        position: fixed;
        top: 85%;
        left: 1%;
        z-index: 99;
        text-align: center;
    }

    .lay-search-wrap.mobile {
        padding: 20px;
    }

    .lay-search__form__col1.mobile,
    .lay-search__form__col2.mobile {
        margin-bottom: 0;
    }

    /* .lay-search__form__col1 ul {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    } */

    .lay-search__form__col1.mobile ul li{
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col2.mobile ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col1.mobile ul li:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lay-search__form__col2.mobile ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* list-style: none; */
    }

    .lay-search_terms__btn.mobile {
        width: 100%;
    }

    .custom-datepicker.mobile {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .lay-search__form__col1.mobile li {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mod-form__set.mobile {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .mod-form__set-between.mobile {
        height: auto;
    }

    .custom-datepicker.mobile {
        width: 100%; 
    }

    .lay-search_terms__list.mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .lay-search_terms__list.mobile img {
        height: 50px;
        padding-right: 10px;
    }
    
    .lay-search_terms__btn.mobile, .pager-frame.mobile .lay-tab__head{
        display: none;
    }

    .lay-list__horizontal.mobile li{
        width: 100%;
    }

    #dispDataMnageData.mobile {
        margin: 0 auto;
        /* padding: 10px; */
        margin-bottom: 20px;
    }

    .lay-tab.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__content.mobile {
        clear: both;
    }

    .lay-tab__content.mobile .pager-frame {
        margin-bottom: 10px;
        width: 100%;
    }

    .flex-container.mobile {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 15px;
        flex-direction: column;
    }

    .lay-tab__content.mobile .result_num {
        margin-bottom: 10px;
        text-align: center;
    }

    .lay-tab__head.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__head.mobile ul {
        display: flex;
        justify-content: center;
    }

    .lay-tab__head.mobile ul li {
        width: 45%;
        font-size: 14px;
        line-height: 30px;
    }

    .lay-tab__head.mobile ul li.current {
        background-color: #008eab;
        color: #fff;
    }

    .lay-list__horizontal.mobile {
        margin-bottom: 10px;
        width: 100%;
    }

    .lay-list__horizontal.mobile ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .lay-list__horizontal__date.mobile {
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__name.mobile {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__checkbox.mobile {
        margin-bottom: 5px;
        text-align: center;
    }

    .lay-list__horizontal__pct.mobile img {
        height: 250px;
        width: 300px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 20px;
    }

    .lay-list__horizontal__comment.mobile {
        padding: 10px 5px;
        margin-top: 10px;
        text-align: center;
    }

    .mod-form__textarea.mobile textarea {
        width: 100%;
        height: 150px;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .lay-search__form.mobile .second-calendar {
        text-align: right;
    }     

    .lay-list__horizontal__btn.mobile {
        padding: 10px;
        text-align: center;
    }

    .mod-btn__round.mobile.small {
        height: 34px;
        font-size: 14px;
        line-height: 32px;
        border-radius: 15px;
    }

    .lay-list__horizontal.mobile ul li {
        width: 100%;
        padding: 10px 0;
        border-top: #008eab 2px solid;
        margin-right: 0;
    
    }

    .mod-form__set-between.mobile {
        /* width: 100%; */
        margin: 0 10px 0 10px;
    }

    .mod-form__text.mobile input[type="text"] {
        /* min-width: 225px;
        max-width: 315px; */
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    #searchNewData.mobile {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .lay-list__horizontal__info.mobile{
        float: right;
        width: 220px;
        display: flex;
        flex-direction: column;
        margin-right: 50px;
    }

    .lay-list__horizontal__data.mobile {
        float: right;
        overflow-y: auto;
        height: 240px;
        max-width: 200px;
        margin-right: 50px;
    }

    .lay-list__horizontal__checkbox.mobile {
        display: none;
    }

    .lay-list__horizontal__img.mobile {
        float: left;
        width: 50%;
        padding-left: 30px;
        padding-bottom: 40px;
    }

    /* body.mobile .image-list {
        text-align: center;
        margin-top: 20px;
    } */
      
    /* body.mobile .current-image img {
        max-width: 100%;
        height: auto;
        border: 1px solid #ddd;
        margin-bottom: 10px;
    } */
      
    .image-slider.mobile {
        width: 80%;
        overflow: hidden;
        margin: 0 auto;
        display: block;
        position: relative;
        bottom: 0;
    }  
    
    .image-slider.mobile {
        position: absolute; 
        bottom: 0;
        left: 0; 
        width: 100%;
        z-index: 10; 
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); 
    }
    
    /* body.mobile .swiper-slide {
        max-width: 30px !important;
        display: flex;
        justify-content: center; 
        align-items: center;
        width: auto; 
    } */

    .main-image-info.mobile {
        /* padding-bottom: 50px; */
        /* overflow-y: auto;  */
        height: 420px; 
        position: relative;
    }
    
    .slider-image-wrapper.mobile {
        position: relative;
        /* padding: 5px; */
        cursor: pointer;
        display: flex;
    }
    
    .slider-image-wrapper.mobile.active {
        border: 2px solid #008eab; 
        border-radius: 4px;
    }
    
    .slider-image-wrapper.mobile img {
        transition: transform 0.5s ease, opacity 0.5s ease;
        opacity: 0.6;
        transform: scale(0.8);
        z-index: 0;
    }
    
    .slider-image-wrapper.mobile.active img {
        transform: scale(1); /* Phóng to ảnh active */
        opacity: 1; /* Ảnh active sẽ rõ nét */
        z-index: 1; /* Ảnh active sẽ ở phía trên */
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3); /* Đổ bóng cho ảnh active */
    }

    .lay-search__form__col1.mobile ul li, .lay-search__form__col2.mobile ul li {
        width: 435px;
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }  

    .lay-search__form__col1.mobile ul {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col2.mobile ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col1.mobile ul{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .mod-form__set.mobile .class_hidden{
        /* display: block; */
        background-color: unset;
        color: black;
    }

    .lay-search__form__col1.mobile .mod-form__set-between{
        display: none;
    }

    .pager.mobile .pagination .pagepre,
    .pager.mobile .pagination  .pagenext{
      display: inline; 
    }
  
    .pager.mobile .pagination .pagepre_icon,
    .pager.mobile .pagination .pagenext_icon {
      display: none;
    }

    .lay-search_terms.mobile {
        margin-bottom: 0;
    }
}

/* Mobile Landscape and smaller tablets */
@media (min-width: 577px) and (max-width: 767px) {
    .container-datamanage.mobile {
        width: 100%;
        padding: 5px;
    }

    .lay-search-wrap.mobile {
        padding: 20px;
    }

    .lay-search__form__col1.mobile,
    .lay-search__form__col2.mobile {
        margin-bottom: 0;
    }

    /* .lay-search__form__col1 ul {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    } */

    .lay-search__form__col1.mobile ul li{
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col2.mobile ul li {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-search__form__col2.mobile ul {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
    }

    .lay-search__form__col1.mobile ul li:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lay-search_terms__btn.mobile {
        width: 100%;
    }

    .custom-datepicker.mobile {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .lay-search__form__col1.mobile ul {
        display: flex;
        /* flex-direction: column; */
    }

    .lay-search__form__col1.mobile li {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mod-form__set.mobile {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .mod-form__set-between.mobile {
        height: auto;
    }

    .custom-datepicker.mobile {
        width: 100%; 
    }

    .lay-search_terms__list.mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .lay-search_terms__list.mobile img {
        height: 50px;
        padding-right: 10px;
    }
    
    .lay-search_terms__btn.mobile, .flex-container.mobile .lay-tab__head{
        display: none;
    }

    .lay-list__horizontal.mobile li{
        width: 100%;
    }

    #dispDataMnageData.mobile {
        margin: 0 auto;
        padding: 10px;
    }

    .lay-tab.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__content.mobile {
        clear: both;
    }

    .lay-tab__content.mobile .pager-frame {
        margin-bottom: 10px;
        width: 100%;
    }

    .flex-container.mobile {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 15px;
        flex-direction: column;
    }

    .lay-tab__content.mobile .result_num {
        margin-bottom: 10px;
        text-align: center;
    }

    .lay-tab__head.mobile {
        width: 100%;
        margin-bottom: 10px;
    }

    .lay-tab__head.mobile ul {
        display: flex;
        justify-content: center;
    }

    .lay-tab__head.mobile ul li {
        width: 45%;
        font-size: 14px;
        line-height: 30px;
    }

    .lay-tab__head.mobile ul li.current {
        background-color: #008eab;
        color: #fff;
    }

    .lay-list__horizontal.mobile {
        margin-bottom: 10px;
        width: 100%;
    }

    .lay-list__horizontal.mobile ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .lay-list__horizontal__date.mobile {
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__name.mobile {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }

    .lay-list__horizontal__checkbox.mobile {
        margin-bottom: 5px;
        text-align: center;
    }

    .lay-list__horizontal__pct.mobile {
        aspect-ratio: 1/1;
        /* border: 1px solid #ccc; */
    }

    .lay-list__horizontal__pct.mobile img {
        aspect-ratio: 1/1;
        height: auto;
        min-height: 300px;
        object-fit: fill;
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .lay-list__horizontal__comment.mobile {
        padding: 10px 5px;
        margin-top: 10px;
        text-align: center;
    }

    .mod-form__textarea.mobile textarea {
        width: 100%;
        height: 150px;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .lay-list__horizontal__btn.mobile {
        padding: 10px;
        text-align: center;
    }

    .mod-btn__round.mobile.small {
        height: 34px;
        font-size: 14px;
        line-height: 32px;
        border-radius: 15px;
    }

    .lay-list__horizontal.mobile ul li {
        width: 100%;
        padding: 10px 0;
        border-top: #008eab 2px solid;
        margin-right: 0;
    
    }

    .mod-form__set-between.mobile {
        width: 100%;
        margin: 0 10px 0 10px;
    }

    .mod-form__text.mobile input[type="text"] {
        /* min-width: 225px; */
        max-width: 315px;
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    #searchNewData.mobile {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .camera_info_photoshoot.mobile {
        display: none;
    }

    /* .mod-form__set .class_hidden{
        visibility: hidden;
    } */

    .pager.mobile .pagination .pagepre,
    .pager .pagination  .pagenext{
      display: none;
    }
  
    .pager.mobile .pagination .pagepre_icon,
    .pager.mobile .pagination .pagenext_icon {
      display: inline;
    }

    .lay-search_terms.mobile {
        margin-bottom: 0;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .pager.mobile .pagination .pagepre,
    .pager .pagination  .pagenext{
      display: inline;
    }
  
    .pager.mobile .pagination .pagepre_icon,
    .pager.mobile .pagination .pagenext_icon {
      display: none;
    }
  }

/* Tablets */
/* @media (min-width: 768px) and (max-width: 1023px) and (min-height: 577px) { */
    .container-datamanage.tablet {
        width: auto;
        /* padding-top: 38px; */
        padding-top: 15px;
        padding-bottom: 38px;
    }

    .lay-search__form__col2.tablet ul {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col1.tablet ul{
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col1.tablet {
        margin-bottom: 24px;
    }

    .lay-search__form__col1.tablet ul li, .lay-search__form__col2.tablet ul li {
        width: 435px;
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .mod-form__set.tablet .class_hidden{
        display: none;
    }

    .pager.tablet .pagination .pagepre,
    .pager.tablet .pagination  .pagenext{
      display: inline; 
    }
  
    .pager.tablet .pagination .pagepre_icon,
    .pager.tablet .pagination .pagenext_icon {
      display: none; 
    }

    .lay-search_terms__list.tablet {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        width: 100%;
    }

    .lay-search_terms__btn.tablet {
        clear: both;
        width: 270px;
        margin: 2px auto;
    }

    .lay-list__horizontal__pct.tablet{
        height: 200px;
        border: 1px solid #ccc;
    }

    .popup-content.tablet form {
        display: flex;
    }

    .popup-content.tablet form .lay-list__horizontal__pct img {
        width: 320px !important;
    }
/* } */

/* Laptops and desktops */
/* @media (min-width: 1024px){ */
    .container-datamanage.desktop {
        width: auto;
        /* padding-top: 38px; */
        padding-top: 15px;
        padding-bottom: 38px;
        margin: 0 auto;
    }

    .lay-search__form__col1.desktop {
        /* display: flex;
        flex-wrap: wrap; */
        margin-bottom: 24px;
    }

    .lay-search__form__col2.desktop ul {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }
    .lay-search__form__col1.desktop ul{
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col1.desktop ul li, .lay-search__form__col2.desktop ul li {
        width: 435px;
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        /* overflow: hidden; */
        box-sizing: border-box;
    }

    .mod-form__text.desktop input[type="text"] {
        max-width: 315px;
    }
    
    .lay-search__btn.desktop button:focus, button:active {
        outline: none;
        border: none;
    }
    
    a, input[type="button"], button {
        -webkit-transition: ease .1s;
        transition: ease .1s;
    }

    .lay-search_terms__list__title.desktop img {
        width: 100%;
    }

    .lay-tab__head.desktop ul li:nth-of-type(2) {
        border-radius: 0 19px 19px 0;
    }

    .custom-datepicker.desktop {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .mod-form__text.desktop input[type="text"] {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    /* .react-datepicker-wrapper.desktop {
        width: 100%;
    } */

    .mod-form__set.desktop .class_hidden{
        display: none;
    }

    .pager.desktop .pagination .pagepre,
    .pager.desktop .pagination  .pagenext{
      display: inline; 
    }
  
    .pager.desktop .pagination .pagepre_icon,
    .pager.desktop .pagination .pagenext_icon {
      display: none; 
    }

    .lay-search_terms__list.desktop {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap !important;
    }

    .lay-search_terms__list__text.desktop {
        width: auto;
    }

    .lay-search_terms-wrap.desktop{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .lay-search_terms__btn.desktop {
        clear: both;
        width: 270px;
    }

    .lay-list__horizontal__pct.desktop {
        border: 1px solid #ccc;
    }
/* } */

/* Ipad pro */

    .container-datamanage.ipadpro {
        width: auto;
        /* padding-top: 38px; */
        padding-top: 15px;
        padding-bottom: 38px;
        margin: 0 auto;
    }

    .lay-search__form__col1.ipadpro {
        /* display: flex;
        flex-wrap: wrap; */
        margin-bottom: 24px;
    }

    .lay-search__form__col2.ipadpro ul {
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }
    .lay-search__form__col1.ipadpro ul{
        display: flex;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        /* list-style: none; */
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .lay-search__form__col1.ipadpro ul li, .lay-search__form__col2.ipadpro ul li {
        width: 435px;
        white-space: nowrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .mod-form__text.ipadpro input[type="text"] {
        max-width: 315px;
    }

    .lay-search__btn.ipadpro button:focus, button:active {
        outline: none;
        border: none;
    }

    .lay-search_terms__list__title.ipadpro img {
        width: 100%;
    }

    .lay-tab__head.ipadpro ul li {
        text-align: center;
        font-size: 15px;
        line-height: 34px;
        width: 240px;
        height: 38px;
        cursor: pointer;
    }

    .lay-tab__head.ipadpro ul li:nth-of-type(2) {
        border-radius: 0 19px 19px 0;
    }

    .custom-datepicker.ipadpro {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    .mod-form__text.ipadpro input[type="text"] {
        width: 100%;
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
        padding-left: 11px;
        outline: 0;
        background-color: #fff;
    }

    /* .react-datepicker-wrapper {
        width: 100%;
    } */

    .mod-form__set.ipadpro .class_hidden{
        display: none;
    }

    .pager.ipadpro .pagination .pagepre,
    .pager.ipadpro .pagination  .pagenext{
    display: inline; 
    }

    .pager.ipadpro .pagination .pagepre_icon,
    .pager.ipadpro .pagination .pagenext_icon {
    display: none; 
    }

    .lay-search_terms__list.ipadpro {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        flex-wrap: nowrap !important;
    }

    .lay-search_terms__list__text.ipadpro {
        width: auto;
    }

    .lay-search_terms-wrap.ipadpro{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .lay-search_terms__btn.ipadpro {
        clear: both;
        width: 270px;
        margin: 2px auto;
    }

    .lay-list__horizontal__pct.ipadpro{
        height: 200px;
        border: 1px solid #ccc;
    }
/*  */

/* Datamanage Popup */
.popup-wrapper {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    overflow: auto;
    z-index: 100;
    cursor: pointer;
}

.popup {
    text-align: center;
    width: 100%;
    max-width: 550px;
    padding: 20px;
    background: rgba(204, 204, 204, 0.5);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.popup-content {
    height: 630px !important;
    text-align: left;
    padding: 10px 55px 10px 55px;
    overflow-y: auto;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin: -50px;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #888;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px 0 0 3px;
    user-select: none;
}

.next {
    right: 75px;
    border-radius: 0 3px 3px 0;
}

.date_popup {
    display: flex;
    max-width: 400px;
    margin-bottom: 10px;
}

.lay-list__popup__checkbox {
    /* margin-bottom: 5px; */
    padding-top: 45px;
    padding-left: 25px;
}

.lay-list__popup__checkbox .checkbox-parts {
    padding-left: 0;
    margin-right: 25px;
    position: relative;
    top: -5px;
}

.lay-list__popup__checkbox .checkbox-parts::before {
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: #fff;
}

.lay-list__popup__checkbox .checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 13px;
    transform: rotate(20deg);
    border-bottom: 2px solid #09ad7e;
    border-right: 2px solid #09ad7e;
}
