/* div, span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
} */

.lay-search_terms_comparisonGraph {
  margin-bottom: 48px;
}

.lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 1200px; 
  padding: 15px 0;
  background-color: #f0f0f0;
}

.lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph__list__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  line-height: 24px;
  padding-right: 15px;
  word-break: break-all;
  margin-left: 20px;
  width: 830px;
}

.lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph__list__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 143px;
}

.lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph__list__title img {
  width: 100%;
}

.comp_slideshow-container {
  width: 830px;
  position: relative;
  margin: auto;
}

.comp_measure_unit {
  position: relative;
  top: 15px;
  right:-4px;
  z-index: 1;
  font-size: 13px;
}

.comptabs {
  display: inline-block; 
  background-color: #fff;
  margin: 0 auto;
  margin-left:650px; 
  width:250px;
}

.comptabs input[name="comp_graph_type"] {
  display: none;
  margin-top:10px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.comptabs input[name="comp_graph_type"]:checked + .comp_graph_item {
  
  color: #f00;
}

.comp_graph_item {
  width: calc(100%/2);
  height: 38px;
  /*border: 1px solid #ccc ;*/
  line-height: 38px;
  text-align: center;
  display: block;
  float: left;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
}
.comp_graph_item:hover {
  opacity: 0.75;
  background-color: #99ffbb;
}

.comp_graph_prev, .comp_graph_next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  margin: -50px;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: pink;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;
  user-select: none;
}

/* Position the "next button" to the right */
.comp_graph_next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

/* On hover, add a black background color with a little bit see-through */
.comp_graph_prev:hover, .comp_graph_next:hover {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
}

.comp_graph_text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.comp_graph_numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.comp_chart_area {
  /*width: 1250px;*/ /*20200313*/
  width: 830px;
  height: 550px;
  position: relative;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  overflow-x: auto; 
}

#cchart {
  position: absolute; 
  left: 0; 
  top: 0; 
}

.comp_modal_view {
  position: absolute;
  width: 600px;
  height: 220px;
  margin: auto;
  border: solid 3px #F00;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
  pointer-events: none;
  transform: translateY(-10px);
  background-repeat: no-repeat;
  background-size: 30px 27px;
  background-position: top 30px center;
  overflow: hidden; 
}

.comp_text_view {
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
  margin-top: -15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.comp_diff_view {
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.comp_measure_view {
	border: solid 1px;
	padding: 0px 10px;
}

.compdots {
  text-align:center;
}

/* The dots/bullets/indicators */
.compdot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  line-height: 1.5;
}

.active, .compdot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .comp_graph_prev, .comp_graph_next,.text {font-size: 11px}
}

.lay-comparisongraph {
  margin-bottom: 49px;
}

.lay-comparisongraph__fig {
  margin-bottom: 41px;
}

.lay-comp_graph__btn {
  width: 563px;
  margin: 0 auto;
}

.lay-comp_graph__btn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.lay-comp_graph__btn ul li {
  width: 270px;
}

.lay-comp_graph__btn .mod-btn__round {
  text-align: center;
  display: block;
  color: #fff;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  background-color: #008eab;
  border-radius: 23px;
}

.lay-comp_graph__btn .mod-btn__round:hover {
  background-color: #f6ab00;
  border: #f6ab00 2px solid;
}

.lay-comp_graph__btn .mod-btn__round:focus,
.lay-comp_graph__btn .mod-btn__round:active {
  outline: none;
}

.lay-comp_graph__btn .mod-btn__roundborder {
  text-align: center;
  display: block;
  color: #008eab;
  font-size: 17px;
  line-height: 42px;
  width: 100%;
  height: 45px;
  border-radius: 23px;
  background-color: #fff;
  border: #008eab 2px solid;
}

.lay-comp_graph__btn .mod-btn__roundborder:hover {
  color: #f6ab00;
  border: #f6ab00 2px solid;
}

.overlay {
  position: fixed;
  top: 0;
  left: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.3);
  z-index: 998; 
  display: none; 
}

.overlay.active {
  display: block;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: none; 
}

.spinner.active {
  display: block; 
  width: 24px;
  height: 24px;
  background: url(/public/img/common/loader.gif)
}

@media (max-width: 1200px) {
  .lay-search_terms_comparisonGraph .lay-search_terms_comparisonGraph__list {
    width: 90%;
  }

  .comptabs {
    margin-left:450px; 

  }

  .comp_slideshow-container {
    width: 630px;
  }

  .comp_chart_area {
    width: 630px;
    height: 500px;

  }

  .comp_graph_prev, .comp_graph_next {
    margin: -45px;
  }

}

@media (max-width: 900px) {

  .comptabs {
    margin-left:450px; 
  }

  .comp_slideshow-container {
    width: 630px;
  }

  .comp_chart_area {
    width: 630px;
    height: 420px;

  }

} 