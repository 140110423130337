#setting2fa {
    margin-top: 60px;
}

.lay-master-set-wrap {
    box-sizing: border-box;
}

.lay-master-set .mail-send__set {
    margin-left: 10%;
}

.lay-master-set__form .mail-send__set .checkbox-input {
    margin-left: 6px;
}

.lay-master-set .mail-input {
    margin-bottom: 50px;
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

#setting2fa .checkbox-parts {
    padding-left: 10px;
    position: relative;
    margin-right: 10px;
}

.lay-master-set.tablet .mail-btn {
    margin-left: 0;
    width: 100%;
}