.lay-master-set .mail-send__set {
    margin-left: 10%;
}

.lay-master-set .checkbox-frame {
    margin-left: 20px;
}

.lay-master-set__form .mail-send__set .checkbox-input {
    margin-left: 6px;
}

.lay-master-set .battery-input input[type="text"] {
    margin-left: 5px;
    width: 60px;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7;
    padding-left: 10px;
    outline: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.lay-master-set .mail-input {
    margin-bottom: 50px;
}

.lay-master-set .mail-input span {
    color: #666;
}

.lay-master-set .mail-input dd{
    max-width: 70% !important;
}

.lay-master-set .mail-input input[type="text"] {
    max-width: none;
}

.lay-master-set .mail-input .add-btn {
    margin-left: 90%;
}

.mod-btn__roundborder.add {
    width: 100px;
    height: 35px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.lay-master-set .mail-btn {
    margin-left: 180px;
    width: 550px;
}

.mod-btn__roundborder.editmoresmall {
    width: 100px;
    height: 38px;
    font-size: 15px;
    line-height: 30px;
    border-radius: 25px;
}

.lay-master-set__form.tablet .wrapper {
    margin-left: 0;
    width: 100%;
    height: 80%;
}

.lay-master-set.tablet .mail-btn {
    margin-left: 60px;
}

.lay-master-set__form.ipadpro .wrapper {
    margin-left: 0;
    width: 100%;
    height: 80%;
}

.lay-master-set.ipadpro .mail-btn {
    margin-left: 80px;
}

#mailEditData .checkbox-parts {
    padding-left: 10px;
    position: relative;
    margin-right: 10px;
}

#set_company_list .lay-master-set.tablet .mail-btn {
    justify-content: space-around;
}

