.lay-bottom__title {
  margin: 5px ;
  text-align: right;
}

@media (max-height: 576px) and (orientation:landscape) {
  .display-main.mobile {
    padding-left: 0;
  }

  .lay-top.mobile {
    align-items: center;
    background: url(/public/img/top/main.jpg) 50% no-repeat;
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }

  .lay-top__title.mobile img {
    max-width: 700px;
    width: 100%;
  }

  .lay-top__title.mobile p {
    font-size: 17px;
    padding-top: 15px;
    text-align: center;
}
}

@media (max-width: 767.98px) and (orientation: portrait) {
  #Main.mobile {
    width: 100%;
    height: 100%;
    padding-left: 0;
    /* overflow: auto; */
    margin-top: 60px;
  }

  #Main.mobile #Contents {
    /* padding-top: 38px; */
    padding-top: 15px;
    padding-bottom: 38px;
    margin: 0 auto;
  }
  
  .lay-top.mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url(/public/img/top/main.jpg) center center no-repeat;
    background-size: cover;
  }

  .lay-top__title.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lay-top__title.mobile img {
    width: 70%;
    max-width: 761px;
  }
  
  .lay-top__title.mobile p {
    text-align: center;
    padding-top: 15px;
    font-size: 14px;
  }

  .display-main.mobile{
    padding-top: 0 !important;
  }
}

/* Mobile landscape */
@media (max-width: 767.98px) and (orientation: landscape) {
  #Main.mobile {
    width: 100%;
    height: 100%;
    padding-left: 0;
    /* overflow: auto; */
  }

  #Main.mobile #Contents {
    /* padding-top: 38px; */
    padding-top: 15px;
    padding-bottom: 38px;
    margin: 0 auto;
  }
  
  .lay-top.mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url(/public/img/top/main.jpg) center center no-repeat;
    background-size: cover;
  }

  .lay-top__title.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lay-top__titl.mobilee img {
    width: 70%;
    max-width: 761px;
  }
  
  .lay-top__title.mobile p {
    text-align: center;
    padding-top: 15px;
    font-size: 14px;
  }
}

/* Tablet portrait và landscape */
/* @media (min-width: 768px) and (max-width: 1023px) { */
  #Main.tablet {
    width: 100%;
    height: 100%;
    /* padding-left: 300px; */
    /* overflow: auto; */
  }

  #Main.tablet #Contents {
    padding-top: 60px;
    padding-bottom: 38px;
    margin: 0 10px;
  }
  
  #Main.tablet #Contents .lay-breadcrumb {
      width: auto;
  }

  .display-main.tablet {
      position: absolute;
      left: 0;
      right: 0;
  }
  
  .lay-top.tablet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url(/public/img/top/main.jpg) center center no-repeat;
    background-size: cover;
  }
  
  .lay-top__title.tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lay-top__title.tablet img {
    width: 80%;
    max-width: 761px;
  }

  .lay-top__title.tablet p {
    text-align: center;
    padding-top: 15px;
    font-size: 17px;
  }
/* } */

/* Laptop and desktop */
/* @media (min-width: 1024px) { */
  #Main.desktop {
    width: 100%;
    height: 100%;
    padding-left: 300px;
    /* overflow: auto; */
  }

  #Main.desktop #Contents {
    width: 990px;
    /* padding-top: 38px; */
    padding-top: 15px;
    padding-bottom: 38px;
    margin: 0 auto;
  }

  .lay-top.desktop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url(/public/img/top/main.jpg) center center no-repeat;
    background-size: cover;
  }
  
  .lay-top__title.desktop img {
    width: 100%;
    max-width: 761px;
  }
  
  .lay-top__title.desktop p {
    text-align: center;
    padding-top: 15px;
    font-size: 17px;
  }
/* } */

/* Ipad Pro */
  #Main.ipadpro {
    width: 100%;
    height: 100%;
    padding-left: 300px;
    /* overflow: auto; */
  }

  #Main.ipadpro #Contents {
    max-width: 990px;
    /* padding-top: 38px; */
    padding-top: 15px;
    padding-bottom: 38px;
    margin: 0 10px;
  }

  .lay-top.ipadpro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url(/public/img/top/main.jpg) center center no-repeat;
    background-size: cover;
  }

  .lay-top__title.ipadpro img {
    width: 100%;
    max-width: 761px;
  }

  .lay-top__title.ipadpro p {
    text-align: center;
    padding-top: 15px;
    font-size: 17px;
  }
/*  */